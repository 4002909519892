<template>
    <div id="fast-delivery-header" v-if="$scope.IS_REGULAR_CUSTOMER">
        <template v-if="shouldShow && !actualCEP">
            <div
                class="content d-flex flex-justify-center flex-align-center"
                @click="editFastDelivery(false)"
            >
                <picture>
                    <source
                        :srcset="`${$scope.CDN_IMAGES}/images/site/fast-delivery/modo-natal-mobile-catalogo-cl.png?auto=compress,format&q=100`"
                        media="(max-width: 576px)"
                    />
                    <img
                        :src="`${$scope.CDN_IMAGES}/images/site/fast-delivery/modo-natal-desktop-catalogo-cl.png?auto=compress,format&q=65`"
                        class="cursor-pointer img-responsive"
                    />
                </picture>
            </div>
        </template>
        <template v-else-if="shouldShow && actualCEP">
            <div id="fast-delivery-image-mobile" class="hidden-xs-up">
                <div class="d-flex flex-justify-center">
                    <img
                        :data-src="`${$scope.CDN_IMAGES}/images/site/fast-delivery/faixa_301x38_modonatal_251124_02.png?auto=compress,format&q=65`"
                        class="lazy lazy-fade"
                    />
                </div>
                <p class="text-center mt-0">Limitando produtos que chegam até 23/12 no seu CEP</p>
                <div class="fast-delivery-info">
                    <div class="d-flex flex-justify-between flex-align-center mx-2 pb-2">
                        <div>CEP: {{ actualCEP }}</div>
                        <a @click="editFastDelivery(false)"> EDITAR CEP </a>
                        <a @click="deactiveFastDelivery"> DESATIVAR MODO </a>
                    </div>
                </div>
            </div>
            <div id="fast-delivery-image-desktop" class="hidden-xs-down">
                <div id="fast-delivery-image-foreground" class="d-flex content">
                    <img
                        :data-src="`${$scope.CDN_IMAGES}/images/site/fast-delivery/faixa_301x38_modonatal_251124_02.png?auto=compress,format&q=65`"
                        class="lazy lazy-fade"
                        width="301"
                    />
                    <div class="text">
                        <p>Limitando produtos que chegam até 23/12 no seu CEP</p>
                    </div>
                    <div class="d-block fast-delivery-info">
                        <div class="d-flex flex-spaced-fixed flex-align-center">
                            <div>CEP: {{ actualCEP }}</div>
                            <a @click="editFastDelivery(false)"> EDITAR CEP </a>
                            <a @click="deactiveFastDelivery"> DESATIVAR MODO </a>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <Modal v-model="activeOrEditModal" class="modal-regulamento">
            <div>
                <div class="cr-modal-header">
                    <img
                        :data-src="`${$scope.CDN_IMAGES}/images/site/fast-delivery/bnr_popup_modonatal_251124.png?auto=compress,format&q=65&w=600`"
                        class="lazy lazy-fade banner-regulamento"
                    />
                </div>
            </div>
            <div class="card-block" :class="{ busy: loading }">
                <h3>Ative o Modo Natal</h3>
                <p v-if="fastDeliveryNotAllowed">
                    Infelizmente, as transportadoras parceiras não conseguem fazer entregas até
                    23/12 para o CEP {{ editCEP }}.
                </p>
                <template v-else>
                    <p>
                        Vamos calcular o prazo de entrega dos produtos a partir do CEP de destino e
                        mostrar apenas os que chegam antes de 23 de dezembro.
                    </p>
                    <div class="form-group">
                        <input
                            type="text"
                            placeholder="Digite seu CEP"
                            class="text-uppercase"
                            v-model="editCEP"
                            v-mask="'#####-###'"
                            maxlength="9"
                            pattern="\d*"
                            :disabled="loading"
                            @keyup.enter="activeFastDelivery"
                            ref="fastCepInput"
                        />
                        <small v-if="error" class="text-danger">
                            {{ error }}
                        </small>
                    </div>
                </template>

                <div class="text-right mt-3">
                    <a class="mr-4" @click="activeOrEditModal = false"> CANCELAR </a>
                    <button
                        class="btn"
                        type="button"
                        @click="editFastDelivery(redirectAfterSuccess)"
                        v-if="fastDeliveryNotAllowed"
                    >
                        Usar novo CEP
                    </button>
                    <button class="btn" type="button" @click="activeFastDelivery" v-else>
                        Ativar
                    </button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
    import Modal from '../common/container/Modal.vue';
    import { EventBus, readCookie, setCookie } from '../../../common/common';
    import { fastDeliveryService } from '../../../common/service/resource';

    export default {
        name: 'HeaderFastDelivery',
        components: { Modal },

        data() {
            return {
                actualCEP: readCookie('_fast-delivery-cep'),
                isProductCatalog: !!window.vueInitialData,
                isProductPage: !!(window.initialScope || {}).ELASTIC_PRODUCT,

                activeOrEditModal: false,
                editCEP: null,
                loading: false,
                error: null,
                fastDeliveryNotAllowed: false,
                redirectAfterSuccess: false,
            };
        },

        created() {
            EventBus.$on('fast-delivery-edit', this.editFastDelivery);
            EventBus.$on('fast-delivery-cep-changed', this.fastDeliveryCepChangeEvent);
        },

        beforeDestroy() {
            EventBus.$off('fast-delivery-edit', this.editFastDelivery);
            EventBus.$off('fast-delivery-cep-changed', this.fastDeliveryCepChangeEvent);
        },

        computed: {
            shouldShow() {
                return this.isProductCatalog || this.isProductPage;
            },
        },

        watch: {},

        methods: {
            activeFastDelivery() {
                if (!this.editCEP) {
                    return;
                }

                this.loading = true;

                fastDeliveryService
                    .query({
                        cep: this.editCEP,
                    })
                    .then((response) => {
                        if (!response.data.allow_fast_delivery) {
                            this.fastDeliveryNotAllowed = true;
                            this.loading = false;
                            return;
                        }

                        setCookie(
                            '_fast-delivery-cep',
                            response.data.fast_delivery_formated_cep,
                            response.data.fast_delivery_expiration,
                            '/'
                        );

                        setCookie(
                            'cep',
                            response.data.fast_delivery_formated_cep,
                            response.data.fast_delivery_expiration,
                            '/'
                        );

                        if (this.redirectAfterSuccess) {
                            window.location.href = '/loja/';
                            return;
                        }

                        EventBus.$emit(
                            'fast-delivery-cep-changed',
                            response.data.fast_delivery_formated_cep
                        );

                        this.loading = false;
                        this.activeOrEditModal = false;
                    })
                    .catch((error) => {
                        this.error =
                            ((error.response || {}).data || {}).message ||
                            'Não foi possível ativar, tente novamente!';
                        this.loading = false;
                    });
            },

            deactiveFastDelivery() {
                setCookie('_fast-delivery-cep', '', -1);
                this.actualCEP = null;
                EventBus.$emit('fast-delivery-cep-changed', '');
            },

            editFastDelivery(redirectAfterSuccess = false) {
                this.error = null;
                this.fastDeliveryNotAllowed = false;
                this.editCEP = null;
                this.activeOrEditModal = true;
                this.redirectAfterSuccess = redirectAfterSuccess;
                this.$nextTick(() => {
                    if (this.$refs.fastCepInput) {
                        this.$refs.fastCepInput.focus();
                    }
                });
            },

            fastDeliveryCepChangeEvent(newCep) {
                this.actualCEP = newCep;
            },
        },
    };
</script>
