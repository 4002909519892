<template>
    <span class="text-primary-5 text-underline">
        <span class="minutes">
            <span>{{ minutes.toString().split('')[0] }}</span>
            <span>{{ minutes.toString().split('')[1] }}</span>
            <span>m</span>
        </span>

        <span class="seconds" v-if="seconds">
            <span>{{ seconds.toString().split('')[0] }}</span>
            <span>{{ seconds.toString().split('')[1] }}</span>
            <span>s</span>
        </span>
    </span>
</template>

<script>
    import { timeHandler } from '../../../common/common';

    export default {
        name: 'CountdownText',

        el: '#vue-countdown-text',

        components: {},

        props: {},

        data() {
            return {
                deadline: null,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        },
        watch: {
            deadline: {
                immediate: true,

                handler(date) {
                    date = this.$scope.order_expiration_date;
                    const dateTest = timeHandler(date).toDate();
                    this.realDeadline = dateTest.getTime();
                },
            },
        },
        mounted() {
            this.update();
            setInterval(this.update.bind(this), 1000);
        },

        methods: {
            getValues() {
                const now = new Date();
                const diff = this.realDeadline ? this.realDeadline - now.getTime() : 0;

                if (diff < 0) {
                    return {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                    };
                }

                const hours = Math.floor(diff / (60 * 60 * 1000));

                return {
                    days: Math.floor(diff / (60 * 60 * 1000 * 24)),
                    hours: this.showDays ? hours % 24 : hours,
                    minutes: Math.floor(diff / (60 * 1000)) % 60,
                    seconds: Math.floor(diff / 1000) % 60,
                };
            },
            update() {
                const values = this.getValues();
                this.days = values.days;
                this.hours = values.hours < 10 ? `0${values.hours}` : values.hours;
                this.minutes = values.minutes < 10 ? `0${values.minutes}` : values.minutes;
                this.seconds = values.seconds < 10 ? `0${values.seconds}` : values.seconds;
            },
        },
    };
</script>
