<template>
    <div>
        <div :class="{ opened: !submitting && !submitted }" class="collapsible">
            <Alert :alert="alert" />

            <TextField
                v-model="email"
                :error="emailError"
                :label="$t('_product._reprint.enterEmail')"
                :placeholder="$t('email')"
                type="email"
            />

            <template v-if="!isProductSingleSize">
                <ProductVariations
                    v-model="sizeToReprint"
                    :product="product"
                    :reprint-mode="true"
                    class="frame-outline mt"
                    :label="$t('_product._reprint.enterSize')"
                />
            </template>

            <Checkbox
                id="reprint-newsletter"
                v-model="subscribeNewsletter"
                class="mt"
                :label="$t('_product._reprint.receiveNews')"
            />

            <button :disabled="!sizeToReprint" class="btn btn-wide btn-lg mt" @click="sendReprint">
                {{ $t('toSend') }}
            </button>
        </div>

        <div :class="{ opened: submitting }" class="collapsible">
            <div class="alert">
                <div class="d-flex flex-spaced-fixed">
                    <div class="cr-spinner-small"></div>
                    <p>{{ $t('wait') }}...</p>
                </div>
            </div>
        </div>

        <div :class="{ opened: submitted }" class="collapsible">
            <p class="alert-success">
                {{ $t('_product._reprint.reprintSubmitted') }}
            </p>

            <button class="btn btn-wide" @click="$emit('done')">OK</button>
        </div>
    </div>
</template>

<script>
    import Alert from '../common/Alert';
    import { productService } from '../../../common/service/resource';
    import { productStore } from '../../common/store/product-store';
    import Checkbox from '../../../../../submodules/src/components/input/checkbox/Checkbox.vue';
    import TextField from '../../../../../submodules/src/components/input/text-field/TextField.vue';
    import SelectField from '../../../../../submodules/src/components/input/select-field/SelectField.vue';
    import ProductVariations from './ProductVariations';

    export default {
        name: 'ProductReprint',

        components: {
            SelectField,
            TextField,
            Checkbox,
            Alert,
            ProductVariations,
        },

        data() {
            return {
                productStoreState: productStore.state,

                email: this.$scope.CUSTOMER ? this.$scope.CUSTOMER.EMAIL : null,
                subscribeNewsletter: false,

                alert: null,
                emailError: null,

                submitting: false,
                submitted: false,
            };
        },

        computed: {
            product() {
                return this.productStoreState.product;
            },

            isProductSingleSize() {
                return this.productStoreState.isProductSingleSize;
            },

            sizeToReprint: {
                get() {
                    return this.productStoreState.sizeToReprint;
                },

                set(val) {
                    this.productStoreState.sizeToReprint = val;
                },
            },
        },

        mounted() {
            if (this.isProductSingleSize) {
                this.sizeToReprint = this.product.variations[0];
            }
        },

        methods: {
            validate() {
                if (!this.sizeToReprint) {
                    this.alert = {
                        message: 'Selecione um tamanho',
                        isError: true,
                    };

                    return false;
                }

                if (!this.email) {
                    this.emailError = 'Informe seu email';
                    return false;
                }

                return true;
            },

            sendReprint() {
                if (!this.validate()) return;

                this.submitting = true;
                productService
                    .requestReprint(this.product.id, this.sizeToReprint.variation_id, {
                        email: this.email,
                        optIn: this.subscribeNewsletter,
                    })
                    .then(() => {
                        if (window.dataLayer) {
                            window.dataLayer.push({
                                event: 'addToReprint',
                                product_gender: this.sizeToReprint.gender,
                                product_size: this.sizeToReprint.size_name,
                            });

                            window.dataLayer.push({
                                event: 'add_to_reprint',
                                product_gender: this.sizeToReprint.gender,
                                product_size: this.sizeToReprint.size_name,
                                ecommerce_v4: {
                                    items: [
                                        {
                                            item_id: this.product.id,
                                            item_name: this.product.name,
                                            price: this.product.price,
                                            item_brand: 'Chico Rei',
                                            item_category: this.product.type.name,
                                            item_category2: this.sizeToReprint.gender,
                                            item_category3: this.sizeToReprint.size_name,
                                            quantity: 1,
                                        },
                                    ],
                                },
                            });
                        }

                        this.submitted = true;
                    })
                    .catch((data) => this.showError(data.response))
                    .then(() => (this.submitting = false));
            },

            showError(response) {
                this.alert = {
                    message: 'Não foi possível enviar os dados.',
                    isError: true,
                };

                if (response.data.errors.email) {
                    this.emailError = response.data.errors.email[0];
                }
            },
        },
    };
</script>
