<template>
    <div class="shipping-form">
        <div class="d-flex product-shipping" style="align-items: baseline">
            <h5 class="mr-auto mt-0">
                Calcular prazos e valores de frete
                <i
                    v-tooltip:left="
                        `Os prazos de entrega começam a contar a partir da confirmação de pagamento e podem variar para mais de uma unidade de um mesmo produto.`
                    "
                    class="icon-cr icon-question-sign"
                ></i>
            </h5>
        </div>
        <template v-if="!loadingShipping">
            <div class="d-flex flex-align-baseline mb-2">
                <p
                    v-if="
                        (!showShippingForm &&
                            showShippingDetails &&
                            ((nationalShipping && cep != null) ||
                                (!nationalShipping && country != null))) ||
                        ((this.fastestOption || this.cheapestOption) && !showShippingForm)
                    "
                    class="text-bold my-0 mr-auto"
                >
                    {{ destinationLabel }}
                </p>
                <a
                    v-if="!showShippingForm"
                    class="text-yanone text-accent text-right cursor-pointer"
                    @click="toggleShippingForm()"
                >
                    {{ showShippingFormLabel }}
                </a>
                <a
                    v-if="showShippingForm"
                    class="text-yanone text-accent text-right cursor-pointer"
                    @click="toggleNationalShipping()"
                >
                    {{ changeShippingTypeLabel }}
                </a>
            </div>
        </template>
        <div id="product-shipping-form" class="collapsible" :class="{ opened: showShippingForm }">
            <Alert :alert="alert" />
            <div class="form-inline mb-2">
                <input
                    v-if="nationalShipping"
                    id="cep"
                    ref="cepInput"
                    v-model="cep"
                    v-mask="'#####-###'"
                    :disabled="loadingShipping"
                    maxlength="9"
                    pattern="\d*"
                    placeholder="Digite o CEP para calcular o frete"
                    type="text"
                    @blur="$emit('blur')"
                    @focus="$emit('focus')"
                    @keyup="onCepType"
                    @keyup.enter="calculateShipping"
                />
                <label v-else class="cr-select">
                    <select id="country" :disabled="loadingShipping" v-model="country">
                        <option selected>Selecione o país</option>
                        <option v-for="country in countries" :value="country">
                            {{ country.name }}
                        </option>
                    </select>
                </label>

                <button
                    :disabled="
                        loadingShipping ||
                        (!nationalShipping && !country) ||
                        (nationalShipping && (!cep || cep.length < 8))
                    "
                    class="btn btn-light"
                    @click="calculateShipping()"
                >
                    Calcular
                </button>
            </div>
        </div>
        <div v-if="loadingShipping">
            <Spinner inline />
        </div>
        <div v-else :class="{ opened: !showShippingForm }" class="collapsible text-yanone">
            <p v-if="productionDate && (cheapestOption || fastestOption)" class="text-info">
                <strong
                    >Previsão de produção:
                    {{ $f.formatDate(productionDate, 'DD [de] MMMM') }}</strong
                >
            </p>
            <div
                v-if="cheapestOption || fastestOption"
                :class="{
                    opened: (fastestOption || cheapestOption) && !showShippingDetails,
                }"
                class="collapsible text-yanone"
            >
                <table class="product-shipping-table" :class="{ hidden: cepNotFound }">
                    <thead>
                        <tr>
                            <th>{{ $t('type') }}</th>
                            <th>Previsão de entrega</th>
                            <th>{{ $t('price') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{ fastestOption.name }}
                            </td>
                            <td
                                v-html="
                                    $t('datesFromToXHtml', [
                                        $f.formatDate(
                                            fastestOption.forecast_initial,
                                            'DD [de] MMMM'
                                        ),
                                        $f.formatDate(fastestOption.forecast, 'DD [de] MMMM'),
                                    ])
                                "
                            />
                            <td v-if="fastestOption.isFree">
                                <span class="badge-info text-uppercase">{{ $t('free') }}</span>
                            </td>
                            <td v-else>
                                {{ $f.formatMoney(fastestOption.price) }}
                            </td>
                        </tr>
                        <tr v-if="fastestOption.name !== cheapestOption.name">
                            <td>
                                {{ cheapestOption.name }}
                            </td>
                            <td
                                v-html="
                                    $t('datesFromToXHtml', [
                                        $f.formatDate(
                                            cheapestOption.forecast_initial,
                                            'DD [de] MMMM'
                                        ),
                                        $f.formatDate(cheapestOption.forecast, 'DD [de] MMMM'),
                                    ])
                                "
                            />
                            <td v-if="cheapestOption.isFree">
                                <span class="badge-info text-uppercase">{{ $t('free') }}</span>
                            </td>
                            <td v-else>
                                {{ $f.formatMoney(cheapestOption.price) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                :class="{ opened: showShippingDetails && !showShippingForm }"
                class="collapsible text-yanone"
            >
                <table class="product-shipping-table" :class="{ hidden: cepNotFound }">
                    <thead>
                        <tr>
                            <th>{{ $t('type') }}</th>
                            <th>{{ $t('forecast') }}</th>
                            <th>{{ $t('price') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="option in regularShipping.options">
                            <td>
                                {{ option.name }}
                            </td>
                            <td
                                v-html="
                                    $t('datesFromToXHtml', [
                                        $f.formatDate(option.forecast_initial, 'DD [de] MMMM'),
                                        $f.formatDate(option.forecast, 'DD [de] MMMM'),
                                    ])
                                "
                            />
                            <td v-if="option.isFree">
                                <span class="badge-info text-uppercase">{{ $t('free') }}</span>
                            </td>
                            <td v-else>
                                {{ $f.formatMoney(option.price) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="!showShippingForm" class="mt-2">
                <a
                    v-if="!showShippingDetails"
                    class="text-yanone text-accent text-right cursor-pointer"
                    @click="toggleShowMoreOptions"
                >
                    Mostrar mais opções
                </a>
                <a
                    v-else
                    class="text-yanone text-accent text-right cursor-pointer"
                    @click="toggleShowMoreOptions"
                >
                    Mostrar menos
                </a>
            </div>
        </div>
        <div v-if="!variation">
            <p class="text-small">
                *O cálculo levará em consideração que o produto está em estoque, para um cálculo
                mais preciso, por favor selecione a variação do produto desejada.*
            </p>
        </div>
    </div>
</template>

<script>
    import { getScope, readCookie, setCookie } from '../../../common/common';
    import {
        carrierService,
        countryService,
        productService,
    } from '../../../common/service/resource';
    import Alert from '../common/Alert';
    import Spinner from '../../../../../submodules/src/components/feedback/spinner/Spinner.vue';

    export default {
        name: 'ProductShipping',

        components: {
            Alert,
            Spinner,
        },

        props: {
            product: {
                type: Object,
                required: true,
            },
            variation: {
                type: Number,
            },
        },

        data() {
            let countryId = getScope('country_id') || 245;
            countryId = parseInt(countryId);
            return {
                alert: null,
                productionDate: null,
                cep: null,
                country: { id_country: countryId },
                countries: [],
                nationalShipping: countryId === 245,
                loadingShipping: false,
                regularShipping: {
                    options: [],
                },
                shippingAdditionalDeadline: null,
                cepNotFound: false,
                showShippingDetails: false,
                showShippingForm: true,
                cheapestOption: null,
                fastestOption: null,
            };
        },

        watch: {
            variation(to) {
                if (to) {
                    this.calculateProduction();
                    this.calculateShipping();
                }
            },
        },

        mounted() {
            if (!this.nationalShipping && (!this.countries || this.countries.length === 0))
                this.fetchCountries();
        },

        computed: {
            showShippingFormLabel() {
                if (
                    this.showShippingDetails ||
                    ((this.fastestOption || this.cheapestOption) && !this.showShippingDetails)
                ) {
                    if (this.nationalShipping && this.cep) {
                        return 'Mudar CEP';
                    } else if (!this.nationalShipping && this.country) {
                        return 'Mudar país';
                    }
                }

                return 'Calcular frete';
            },

            changeShippingTypeLabel() {
                return this.nationalShipping
                    ? 'Sua entrega é fora do Brasil?'
                    : 'Sua entrega é no Brasil?';
            },

            destinationLabel() {
                return this.nationalShipping ? this.cep : this.country.name;
            },
        },

        methods: {
            calculateProduction() {
                let variation = this.variation;
                productService
                    .calculateProduction(this.product.id, {
                        variation: variation,
                    })
                    .then((response) => {
                        this.productionDate = response.data;
                    });
            },

            fetchCountries() {
                countryService
                    .query({ active: true, allow_shipping: 1 })
                    .then((response) => {
                        this.countries = response.data.data;
                        const countryFound = this.countries.filter(
                            (c) => c.id_country === this.country.id_country
                        );
                        if (countryFound[0]) {
                            this.country = countryFound[0];
                        }
                    })
                    .catch(() =>
                        this.$refs.shippingAlert.shippingAlert(
                            'Não foi póssível carregar as opções de entrega',
                            true
                        )
                    );
            },

            onCepType(ev) {
                let key = ev.which;

                // Verifica se a tecla pressionada é um número
                if (
                    (ev.target.value.length === 9 && key >= 48 && key <= 57) ||
                    (key >= 96 && key <= 105)
                ) {
                    this.calculateShipping();
                }
            },

            calculateShipping() {
                if (this.loadingShipping) return;
                if (!this.nationalShipping && this.country && this.country.id_country === 245) {
                    this.toggleNationalShipping();
                    return;
                }
                if (
                    (!this.nationalShipping && this.country && this.country.id_country !== 245) ||
                    (this.nationalShipping && this.cep && this.cep.length >= 9)
                ) {
                    this.loadingShipping = true;

                    this.regularShipping.options.length = 0;

                    this.cepNotFound = false;

                    this.$nextTick(() => {
                        if (this.$refs.cepInput) this.$refs.cepInput.blur();
                    });

                    this.getCarriers()
                        .then((data) => {
                            if (
                                data.data.data != undefined &&
                                data.data.data.responses != undefined
                            ) {
                                let additionalDeadline = 0;

                                this.showJfMessage = data.data.data.is_jf;
                                this.showSpecialPriceMessage = false;
                                this.hasPrimeQuotation = false;

                                let fastestCarrier = {
                                    isPrime: data.data.data.responses[0].prime,
                                    isFree:
                                        data.data.data.responses[0].price === 0 &&
                                        data.data.data.responses[0].carrier_id !== 3,
                                    forecast: data.data.data.responses[0].delivery_forecast_final,
                                    forecast_initial:
                                        data.data.data.responses[0].delivery_forecast_initial,
                                    name: !data.data.data.responses[0].prime
                                        ? data.data.data.responses[0].name
                                        : '',
                                    price: data.data.data.responses[0].price,
                                };
                                let cheapestCarrier = {
                                    isPrime: data.data.data.responses[0].prime,
                                    isFree:
                                        data.data.data.responses[0].price === 0 &&
                                        data.data.data.responses[0].carrier_id !== 3,
                                    forecast: data.data.data.responses[0].delivery_forecast_final,
                                    forecast_initial:
                                        data.data.data.responses[0].delivery_forecast_initial,
                                    name: !data.data.data.responses[0].prime
                                        ? data.data.data.responses[0].name
                                        : '',
                                    price: data.data.data.responses[0].price,
                                };

                                for (let value of data.data.data.responses) {
                                    if (value.carrier_id !== 3) {
                                        this.hasPrimeQuotation =
                                            this.hasPrimeQuotation || value.prime;
                                        this.showSpecialPriceMessage =
                                            this.showSpecialPriceMessage ||
                                            (value.price === 0 && value.carrier_id !== 4);

                                        this.regularShipping.options.push({
                                            isPrime: value.prime,
                                            isFree: value.price === 0 && value.carrier_id !== 3,
                                            forecast: value.delivery_forecast_final,
                                            forecast_initial: value.delivery_forecast_initial,
                                            name: !value.prime ? value.name : '',
                                            price: value.price,
                                        });
                                        if (
                                            fastestCarrier.forecast > value.delivery_forecast_final
                                        ) {
                                            fastestCarrier =
                                                this.regularShipping.options[
                                                    this.regularShipping.options.length - 1
                                                ];
                                        }
                                        if (cheapestCarrier.price > value.price) {
                                            cheapestCarrier =
                                                this.regularShipping.options[
                                                    this.regularShipping.options.length - 1
                                                ];
                                        }
                                    }

                                    if (value.additional_deadline > 99) {
                                        additionalDeadline = value.additional_deadline;
                                    }
                                }

                                this.cheapestOption = cheapestCarrier;
                                this.fastestOption = fastestCarrier;

                                this.shippingAdditionalDeadline =
                                    additionalDeadline > 99 ? additionalDeadline : null;
                            } else {
                                this.cepNotFound = true;
                            }

                            this.showShippingForm = false;
                            this.loadingShipping = false;
                        })
                        .catch((response) => {
                            this.loadingShipping = false;
                            this.showShippingForm = true;
                            this.handleShippingApiError(
                                response,
                                'Não foi possível calcular as opções de entrega'
                            );
                        });
                }
            },
            getCarriers() {
                setCookie('cep', this.cep);
                setCookie('country_id', this.country ? this.country.id_country : 245);

                const params = {
                    cep: this.cep,
                    pais: this.country.id_country,
                    default_group: !this.$scope.IS_REGULAR_CUSTOMER ? 3 : null,
                    product: this.product.id,
                    variation: this.variation,
                };

                return carrierService.query(params);
            },

            toggleShippingForm() {
                this.showShippingForm = !this.showShippingForm;
            },

            toggleNationalShipping() {
                this.nationalShipping = !this.nationalShipping;

                this.cep = null;

                if (!this.nationalShipping && (!this.countries || this.countries.length === 0))
                    this.fetchCountries();
                else if (this.nationalShipping) {
                    this.country = this.countries.filter((c) => c.id_country === 245)[0];
                }
            },

            toggleShowMoreOptions() {
                this.showShippingDetails = !this.showShippingDetails;
            },

            handleShippingApiError(response, defaultMessage) {
                try {
                    this.alert = {
                        message: response.response.data.message,
                        isError: true,
                    };
                } catch (error) {
                    this.alert = {
                        message: defaultMessage,
                        isError: true,
                    };
                }
            },
        },
    };
</script>
