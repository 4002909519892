<template>
    <div>
        <div
            class="fast-buy flex-justify-around"
            :style="{
                bottom: product.type.id === 6 && !showAddToCart ? '6.7rem !important' : '3.3rem',
            }"
            @mouseleave="showAddToCart = true"
            v-if="!productAddedToCart.status"
            :class="showAddToCart ? 'first-color' : 'second-color'"
            @click.stop.prevent="showAddToCart && sizes && sizes[0] ? onSizeClick(sizes[0]) : null"
        >
            <div
                key="add-to-cart"
                v-if="showAddToCart"
                class="text-uppercase w-full h-full text-center"
                @mouseenter="handleMouseEnter"
            >
                Adicionar à sacola
            </div>

            <div
                v-else-if="availableModelings && availableModelings.length > 0 && !selectedModelId"
                v-for="modeling in availableModelings"
                class="select-add-to-cart"
                @click.stop.prevent="setModeling(modeling.id)"
                :key="'modeling-' + modeling.id"
            >
                <span>{{ modeling.url }}</span>
            </div>

            <div v-else-if="!selectedModelId && originalModelingsCount > 0" key="another-color-1">
                <span>Selecione outra Cor</span>
            </div>

            <div
                v-else-if="availableSizes && availableSizes.length > 0 && !selectedSizeId"
                v-for="size in availableSizes"
                class="select-add-to-cart"
                @click.stop.prevent="onSizeClick(size)"
                :key="'size-' + size.size_id"
            >
                <span
                    :style="{
                        ':hover': {
                            transform:
                                product.type.id === 6
                                    ? 'scale(1.1) !important'
                                    : 'scale(1.2) !important',
                        },
                        'font-size': product.type.id === 6 ? '15px !important' : '18px !important',
                    }"
                    >{{ size.size_name }}</span
                >
            </div>

            <div v-else-if="!selectedSizeId" key="another-color-2">
                <span>Selecione outra Cor</span>
            </div>

            <div
                key="loading"
                class="d-flex flex-align-center"
                v-else-if="loadingCart"
                :style="{ marginTop: product.type.id === 6 ? '3.3rem' : '0' }"
            >
                <Spinner small />
            </div>
        </div>

        <div
            v-else-if="productAddedToCart.status && !loadingCart"
            class="fast-buy d-flex flex-justify-around text-white"
            :style="{
                'background-color': productAddedToCart.bg_color,
                bottom: '3.3rem',
            }"
        >
            <div class="text-uppercase">
                {{ productAddedToCart.text }}
            </div>
        </div>
    </div>
</template>
<script>
    import BaseVariations from './BaseVariations.vue';
    import ProductVariations from './ProductVariations.vue';
    import Spinner from '../../../../../submodules/src/components/feedback/spinner/Spinner.vue';
    import { cartStore } from '../../common/store/cart-store';
    import { EventBus } from '../../../common/common';

    export default {
        name: 'ProductListAddToCartDesktop',
        components: { ProductVariations, Spinner },

        extends: BaseVariations,

        data() {
            return {
                loadingCart: false,
                showAddToCart: true,
                productAddedToCart: {
                    text: null,
                    bg_color: '#000000',
                    status: false,
                },

                internalSelectedVariation: null,
            };
        },

        computed: {
            availableSizes() {
                if (!this.sizes) return [];
                return this.sizes.filter((size) => this.shopMode && size.has_any === true);
            },

            availableModelings() {
                if (!this.modelings) return [];
                return this.modelings.filter(
                    (modelings) => this.shopMode && modelings.has_any === true
                );
            },
        },

        watch: {
            selectedSizeId: {
                handler(to) {
                    this.internalSelectedVariation = null;

                    if (to) {
                        this.showAddToCart = false;
                        this.internalSelectedVariation = this.updateSelectedVariation();
                        this.reportToTagManager();
                        this.addToCart(false);
                    }
                },
            },
        },

        methods: {
            handleMouseEnter() {
                this.showAddToCart = this.product.variations.length <= 1;

                if (
                    this.availableModelings.length === 1 &&
                    this.originalModelingsCount === 1 &&
                    this.product.is_clothing
                ) {
                    this.setModeling(this.availableModelings[0].id);
                } else {
                    this.setModeling(null);
                }

                this.setSize(null);
            },

            reportToTagManager() {
                if (!this.internalSelectedVariation) {
                    return;
                }

                this.loadingCart = true;
                let reportItems = [];
                let reportItemsV4 = [];
                const variation = this.internalSelectedVariation;

                if (!this.$scope.IS_REGULAR_CUSTOMER) {
                    const variationInfo = this.buildEventVariationInfo(variation);
                    window.dataLayer.push(this.buildCartProductEvent(variationInfo));
                    reportItems.push(this.buildCartEventItem(variationInfo, 1));
                    reportItemsV4.push(this.buildCartEventItemV4(variationInfo, 1));
                } else {
                    const variationInfo = this.buildEventVariationInfo(variation);
                    window.dataLayer.push(this.buildCartProductEvent(variationInfo));
                    reportItems.push(this.buildCartEventItem(variationInfo));
                    reportItemsV4.push(this.buildCartEventItemV4(variationInfo));
                }

                window.dataLayer.push({
                    event: 'addToCart',
                    ecommerce: {
                        currencyCode: 'BRL',
                        add: { products: reportItems },
                    },
                });

                window.dataLayer.push({ ecommerce_v4: null });
                window.dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce_v4: {
                        items: reportItemsV4,
                    },
                });
                this.loadingCart = false;
            },

            buildEventVariationInfo(variation) {
                const info = {};
                info.sizeName = variation.size_name === 'Único' ? 'Padrão' : variation.size_name;

                switch (variation.gender) {
                    case 'M':
                        info.genderName = 'Masculino';
                        break;
                    case 'F':
                        info.genderName = 'Feminino';
                        break;
                    case 'U':
                        info.genderName = 'Unisex';
                        break;
                    case 'I':
                        info.genderName = 'Infantil';
                        break;
                    default:
                        info.genderName = '';
                }

                info.colorName = variation.product_color_name;

                return info;
            },

            buildCartProductEvent(sizeInfo) {
                const event_id = 'ADTC_' + this.product.id;
                this.facebookEventId = event_id;
                return {
                    event: 'addToCartProduct',
                    'event-id-fb': event_id,
                    'product-size-name': sizeInfo.sizeName,
                    'product-gender-name': sizeInfo.genderName,
                };
            },

            buildCartEventItem(variationInfo, quantity = 1) {
                const reportProduct = {
                    name: this.product.name,
                    id: `${this.product.id}`,
                    price: `${this.price}`,
                    brand: 'Chico Rei',
                    category: this.category,
                    quantity,
                };

                reportProduct.variant = this.buildVariantReportItem(
                    variationInfo.genderName,
                    variationInfo.sizeName,
                    variationInfo.colorName
                );

                return reportProduct;
            },

            buildCartEventItemV4(variationInfo, quantity = 1) {
                const reportProductV4 = {
                    item_name: this.product.name,
                    item_id: `${this.product.id}`,
                    price: `${this.price}`,
                    item_brand: 'Chico Rei',
                    item_category: this.product.type.name,
                    item_category2: variationInfo.genderName,
                    item_category3: variationInfo.sizeName,
                    item_category4: variationInfo.colorName,
                    quantity: quantity,
                };

                reportProductV4.variant = this.buildVariantReportItem(
                    variationInfo.genderName,
                    variationInfo.sizeName,
                    variationInfo.colorName
                );

                return reportProductV4;
            },

            buildVariantReportItem(genderName, sizeName, colorName) {
                return `${genderName}-${sizeName}-${colorName}`
                    .replace('--', '-')
                    .replace(/-$/, '')
                    .replace(/^-/, '');
            },

            getCartBodyRequest() {
                if (!this.internalSelectedVariation) {
                    return;
                }

                const products = [];

                products.push({
                    product_id: this.product.id,
                    variation_id: this.internalSelectedVariation.variation_id,
                    quantity: 1,
                });

                const data = new FormData();
                data.append('json', '1');
                data.append('products', JSON.stringify(products));
                // data.append('fb_event_id', this.facebookEventId);
                return data;
            },

            addToCart(openSidebarCart = true) {
                if (!this.internalSelectedVariation) {
                    this.loadingCart = false;
                }

                const cartBodyRequest = this.getCartBodyRequest();
                this.loadingCart = true;
                cartStore
                    .addProduct(cartBodyRequest, openSidebarCart)
                    .then((response) => {
                        this.$scope.cart_id = response.data.cart_id;
                        const products = JSON.parse(cartBodyRequest.get('products'));
                        const productId = products[0].product_id;
                        if (this.isMobile) {
                            EventBus.$emit('list-product-added-to-cart-mobile', productId);
                        }
                        this.productAddedToCart = {
                            text: 'Produto adicionado',
                            bg_color: '#000000',
                            status: true,
                        };
                    })
                    .catch(() => {
                        this.loadingCart = false;

                        this.productAddedToCart = {
                            text: 'Erro ao adicionar',
                            bg_color: '#ff0000',
                            status: true,
                        };
                    })
                    .then(() => {
                        this.resetFields();
                        this.loadingCart = false;
                        setTimeout(() => {
                            this.productAddedToCart.status = false;
                            this.$emit('close');
                        }, 1500);
                        this.showAddToCart = true;
                    });
            },
        },
    };
</script>
