<template>
    <div
        class="d-inline-flex flex-align-start"
        :class="{ 'flex-column': orientation === 'vertical' }"
    >
        <div
            v-if="activePill"
            class="pos-absolute nav-pill-indicator nav-pill-indicator rounded-full px py-1 text-nowrap overflow-hidden"
            :class="indicatorClass"
            :style="style"
        >
            <wbr />
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Navbar',

    props: {
        value: [Number, String, Object],
        orientation: {
            type: String,
            default: 'horizontal',
        },
        idleClass: {
            type: String,
            default: 'text-default-5',
        },
        hoverClass: {
            type: String,
            default: 'bg-default-1',
        },
        selectedClass: {
            type: String,
            default: 'text-white',
        },
        indicatorClass: {
            type: String,
            default: 'bg-primary',
        },
    },

    provide() {
        return {
            pills: {
                activePill: () => this.activePill,
                orientation: () => this.orientation,
                idleClass: () => this.idleClass,
                hoverClass: () => this.hoverClass,
                selectedClass: () => this.selectedClass,
                registerPill: this.registerPill,
                setActivePill: this.setActivePill,
            },
        }
    },

    data() {
        return {
            pills: [],
            activePill: null,
            style: {
                top: null,
                left: null,
                maxWidth: null,
            },
        }
    },

    destroyed() {
        this.pills = []
    },

    watch: {
        value: {
            handler(to) {
                this.activePill = this.pills.find((pill) => pill.value === to)
            },
        },

        activePill() {
            if (this.noPillActive && this.pills.length > 0) {
                this.setFirstValidPillAsActive()
            } else {
                this.style.top = this.activePill.$el.offsetTop + 'px'
                this.style.left = this.activePill.$el.offsetLeft + 'px'
                this.style.maxWidth = this.activePill.$el.clientWidth + 'px'
            }
        },

        pills(to) {
            if (to && to.length > 0 && this.noPillActive) {
                this.setFirstValidPillAsActive()
            }
        },
    },

    computed: {
        noPillActive() {
            return this.activePill === undefined || this.activePill === null
        },
    },

    methods: {
        registerPill(pill) {
            if (this.pills.indexOf(pill) < 0) {
                this.pills.push(pill)

                pill.$once('hook:destroyed', () => {
                    this.unregisterPill(pill)
                })
            }
        },

        unregisterPill(pill) {
            this.pills = this.pills.slice().filter((t) => t !== pill)
        },

        setActivePill(pill) {
            this.$emit('input', pill.value)
        },

        setFirstValidPillAsActive() {
            const modelPill = this.pills.find(
                (pill) => pill.value === this.value
            )

            if (modelPill) {
                this.activePill = modelPill
            } else {
                this.setActivePill(this.pills[0])
            }
        },
    },
}
</script>
