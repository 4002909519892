import { api, http } from '../common';

function resource(path, actions = {}) {
    return Object.assign(
        {
            get: (id, params = {}, config = {}) => api.get(`${path}/${id}`, { params, ...config }),
            save: (obj, params = {}, config = {}) => api.post(path, obj, { params, ...config }),
            query: (params = {}, config = {}) => api.get(path, { params, ...config }),
            update: (id, obj, config = {}) => api.put(`${path}/${id}`, obj, config),
            delete: (id) => api.delete(`${path}/${id}`),
        },
        actions
    );
}

//region Address
export const addressService = {
    queryPostcode: (params = {}) => api.get(`/addresses/postcode`, { params }),
    validateAndSave: (params = {}) => api.get(`/addresses/postcode`, { params }),
};
//endregion

//region Carrier
export const carrierService = resource('/carriers');
//endregion

//region Carrier
export const categoryService = resource('/categories', {
    getFeaturedProducts: (id, params = {}) => api.get(`/categories/${id}/featured`, { params }),
});
//endregion

//region Cart
export const cartService = resource('/carts', {
    getLatest: (params = {}) => api.get(`/carts/latest`, { params }),
    addToCart: (obj, params = {}) => http.post('/cart.php', obj, { params }),
    getProducts: (id, params = {}) => api.get(`/carts/${id}/products/`, { params }),
    updateProducts: (id, obj, params = {}) => api.post(`/carts/${id}/products/`, obj, { params }),
    addDiscount: (id, obj, params = {}) => api.post(`/carts/${id}/discounts/`, obj, { params }),
    removeDiscount: (cartId, discountId) => api.delete(`/carts/${cartId}/discounts/${discountId}`),
    addPrime: (id, params = {}) => api.post(`/carts/${id}/prime`, { params }),
    removePrime: (id, params = {}) => api.post(`/carts/${id}/prime`, { quantity: -1, ...params }),
    paymentMethods: (id, params = {}, config = {}) =>
        api.get(`/carts/${id}/payment_methods`, { params, ...config }),
    checkout: (id, obj, params = {}, config = {}) =>
        api.post(`/carts/${id}/checkout/`, obj, { params, ...config }),
    copy: (id, obj, params = {}) => api.post(`/carts/${id}/copy`, obj, { params }),
    updateForCustomer: (id, obj, params = {}) =>
        api.post(`/carts/${id}/for_customer`, obj, { params }),
    updateOrganization: (id, obj, params = {}) =>
        api.post(`/carts/${id}/organization`, obj, { params }),
    createCart: (obj, params = {}) => http.post('/create_cart.php', obj, { params }),
    changeCart: (obj, params = {}) => http.post('/change_cart.php', obj, { params }),
});
//endregion

//region Country
export const countryService = resource('/countries');

// TODO Corrigir
export const countryRegionService = {
    query: (params = {}, config = {}) =>
        api.get(`https://arcoiro.chicorei.com/country_region`, {
            params,
            ...config,
        }),
};

//endregion

//region Customer
export const customerService = resource('/customers', {
    getExchanges: (id, params = {}) => api.get(`/customers/${id}/exchanges`, { params }),
    getExchangeableOrders: (id, params = {}) =>
        api.get(`/customers/${id}/orders/exchangeable`, { params }),
    getDiscounts: (id, params = {}) => api.get(`/customers/${id}/discounts`, { params }),
    getPhotos: (id, params = {}) => api.get(`/customers/${id}/gallery`, { params }),
    savePhoto: (id, obj, params) => api.post(`/customers/${id}/gallery`, obj, { params }),
    getWishlist: (id, params = {}) => api.get(`/customers/${id}/wishlist`, { params }),
    addWishlistProduct: (id, obj) => api.post(`/customers/${id}/wishlist`, obj),
    deleteWishlistProduct: (customerId, wishlistPoductId) =>
        api.delete(`/customers/${customerId}/wishlist/${wishlistPoductId}`),
    postAvatar: (customerId, obj) => api.post(`/customers/${customerId}/avatar`, obj),
    deleteAvatar: (customerId) => api.delete(`/customers/${customerId}/avatar`),
    sendReferEmail: (customerId, obj) => api.post(`/customers/${customerId}/refer`, obj),
    exchangeLoyaltyPoints: (customerId) =>
        api.post(`/customers/${customerId}/exchange_loyalty_points`, {}),
    getRepresentedCustomers: (customerId, params = {}) =>
        api.get(`/customers/${customerId}/represented`, { params }),
    getCashbacks: (id, params = {}) => api.get(`/customers/${id}/cashbacks`, { params }),
    getCashbackBalances: (id, params = {}) =>
        api.get(`/customers/${id}/cashback_balance`, { params }),
});
//endregion

//region Customer Address
export const getCustomerAddressService = (customerId) =>
    resource(`/customers/${customerId}/addresses`, {
        makeDefault: (id) => api.put(`/customers/${customerId}/addresses/${id}/default`),
    });
//endregion

//region Authorize Pickup Person
export const authorizePickupPersonService = {
    saveAuthorizedPerson: (customerId, orderId, params) =>
        api.post(`/customer/${customerId}/orders/${orderId}/authorized_pickup_persons`, { params }),
    updateAuthorizedPerson: (customerId, orderId, authorizedPersonId, params) =>
        api.put(
            `/customer/${customerId}/orders/${orderId}/authorized_pickup_persons/${authorizedPersonId}`,
            { params }
        ),
};
//endregion

//region Customer Order
export const getCustomerOrderService = (customerId) =>
    resource(`/customers/${customerId}/orders`, {
        getRepresented: (params = {}) =>
            api.get(`/customers/${customerId}/orders/represented`, { params }),
    });
//endregion

//region Discount
export const discountService = resource('/discounts', {
    find: (params = {}) => api.get(`/discounts/find`, { params }),
});

//region Description
export const fabricService = {
    getAllowedProductionWithoutStock: (params = {}) =>
        api.get(`/fabrics/allowed_production_without_stock`, { params }),
};
//endregion

//region Featured Discount
export const featuredDiscountService = resource('/featured_discounts', {
    sendEmail: (id, obj) => api.post(`/featured_discounts/${id}/send_email`, obj),
});
//endregion

//region Exchange
export const exchangeService = resource('/exchanges', {
    getCauses: () => api.get(`/exchanges/causes`),
    getCausesReasons: () => api.get(`/exchanges/causes_reasons`),
});
//endregion

//region Fast Delivery
export const fastDeliveryService = resource('/fast_delivery');
//endregion

//region Gallery
export const galleryService = resource('/gallery');
//endregion

//region Hashtag
export const hashtagService = resource('/hashtags');
//endregion

//region Home Collection
export const homeCollectionService = resource('/home/collections');
//endregion

//region Home Wholesalers
export const homeWholesalerService = resource('/home/wholesalers');
//endregion

//region Order
export const orderService = {
    getOrderTracking: (id, params = {}) => api.get(`/orders/${id}/tracking`, { params }),
};
//endregion

//region Payment Link
export const paymentLinkService = {
    process: (id, obj, params = {}) => api.post(`/payment_link/${id}/process`, obj, { params }),
};
//endregion

//region Partner
export const partnerService = resource('/partners', {
    countArts: (id, params = {}) => api.get(`/partners/${id}/arts/count`, { params }),
    sendContactEmail: (obj, params = {}) => api.post(`/partners/contact`, obj, { params }),
});
//endregion

//region Mais Uni
export const maisUniService = resource('/maisuni', {
    sendEmail: (obj, params = {}) => api.post(`/maisuni/contact`, obj, { params }),
});
//endregion

//region Product
export const productService = resource('/products', {
    queryStock: (id, params = {}) => api.get(`/products/${id}/stock`, { params }),
    queryComments: (id, params = {}) => api.get(`/products/${id}/comments`, { params }),
    queryPhotos: (id, params = {}) => api.get(`/products/${id}/photos`, { params }),
    addComment: (id, obj, params = {}) => api.post(`/products/${id}/comments`, obj, { params }),
    requestReprint: (productId, productSizeId, obj, params) =>
        api.post(`/products/${productId}/reprint/${productSizeId}`, obj, {
            params,
        }),
    getFeatured: (params = {}) => api.get(`/products/featured`, { params }),
    calculateProduction: (id, params = {}) => api.get(`/products/${id}/production`, { params }),
    getStatistics: (id, params = {}) => api.get(`/products/${id}/review`, { params }),
    productCombo: (id, params = {}) => api.get(`/products/${id}/combo`, { params }),
});
//endregion

//region Comment
export const commentService = resource('/customer_comments', {
    markUseful: (id) => api.put(`/customer_comments/${id}/mark_useful`),
});
//endregion

//region Comment
export const avaliationService = {
    getAvaliation: (orderId, token, params = {}) =>
        api.get(`/orders/${orderId}/review/${token}`, { params }),
    postProductAvaliation: (orderId, token, productId, obj, params = {}) =>
        api.post(`/orders/${orderId}/review/${token}/product/${productId}`, obj, { params }),
};
//endregion

//region Product Model
export const productModelService = resource('/product_model');
//endregion

// TODO Corrigir

//region Product Type
export const productTypeService = {
    get: (id, params = {}, config = {}) =>
        api.get(`https://arcoiro.chicorei.com/tipo_produto_lang/${id}`, {
            params,
            ...config,
        }),
};
//endregion

//region Product Type
export const organizationService = resource('/organizations');
//endregion

//region Facebook Conversions
export const facebookService = resource('/facebook', {
    initiatedCheckout: (id) => api.post(`/facebook/initiated_checkout/${id}`),
});
//endregion

//region Size Guide
export const sizeGuideService = resource('/size_guide', {
    getSizeGuideMedia: (params = {}, config = {}) =>
        api.get(`/size_guide/`, {
            params,
            ...config,
        }),
});
//endregion

//region Free SHipping
export const shippingService = resource('/shipping_control');
//endregion

//region Size Guide
export const basicsCollectionService = resource('/page_basics_collection', {
    getInfo: (params = {}, config = {}) =>
        api.get(`/page_basics_collection/`, {
            params,
            ...config,
        }),
});
//endregion

//region Cashback
export const cashbackService = resource('/cashback', {
    getInfo: () => api.get(`/cashback/info`),
});
//endregion
