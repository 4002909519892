<template>
    <FieldWrapper :id="id" :error="error" :hint="hint" :label="label">
        <div class="cr-select">
            <select v-model="innerValue" :id="id" :class="getInputClasses" :disabled="disabled">
                <option v-if="placeholder" :value="null" disabled selected>
                    {{ placeholder }}
                </option>
                <slot />
            </select>
        </div>
    </FieldWrapper>
</template>

<script>
    import { fieldWrapperProps } from '../../../helpers/input';
    import FieldWrapper from '../field-wrapper/FieldWrapper';

    export default {
        compatConfig: { COMPONENT_V_MODEL: false },

        name: 'SelectField',

        components: { FieldWrapper },

        model: {
            prop: 'modelValue',
            event: 'update:modelValue',
        },

        props: {
            ...fieldWrapperProps,
            name: String,
            placeholder: String,
            disabled: Boolean,
            modelValue: {
                default: null,
            },
            autoCleanErrors: {
                type: Boolean,
                default: true,
            },
            inputClasses: String,
        },

        emits: ['change', 'update:modelValue'],

        data() {
            return {
                innerValue: this.modelValue,
            };
        },

        computed: {
            getInputClasses() {
                let classes = this.inputClasses ? this.inputClasses.split(' ') : [];
                if (this.error) classes.push('input-danger');
                return classes;
            },
        },

        watch: {
            modelValue(newValue) {
                this.innerValue = newValue;
            },

            innerValue(newValue) {
                if (this.error && this.autoCleanErrors) {
                    this.$emit('update:error', null);
                }

                this.$emit('change', newValue); // for backwards compatibility
                this.$emit('update:modelValue', newValue);
            },
        },
    };
</script>
