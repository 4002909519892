<template>
    <Modal :value="value" @input="$emit('input', $event)" class="modal-regulamento">
        <div class="card">
            <div class="card-block card-toolbar">
                <span class="h5 card-title">Regras para Frete Grátis</span>
            </div>
            <div class="card-block">
                <Spinner v-if="!shippingControl" />
                <template v-else>
                    <p>
                        Valor mínimo de compra para frete grátis (já aplicados todos os possíveis
                        descontos):
                    </p>
                    <div>
                        <table class="table-striped mt m-auto">
                            <thead>
                                <tr>
                                    <th>Estado</th>
                                    <th class="text-center">Sigla</th>
                                    <th class="text-center">Valor Mínimo para Frete Grátis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in shippingControl" :key="i">
                                    <td>{{ item.name }}</td>
                                    <td class="text-center">{{ item.iso_code }}</td>
                                    <td class="text-center">
                                        {{ $f.formatMoney(item.min_value) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
            <div class="card-block text-right">
                <button class="btn" type="button" @click="$emit('input', false)">Fechar</button>
            </div>
        </div>
    </Modal>
</template>
<script>
    import { shippingService } from '../../../common/service/resource';
    import Spinner from '../../../../../submodules/src/components/feedback/spinner/Spinner.vue';
    import Modal from '../common/container/Modal.vue';
    import { EventBus } from '../../../common/common';

    export default {
        name: 'ShippingRules',

        components: {
            Spinner,
            Modal,
        },

        props: {
            value: {
                default: false,
            },
            listenEvent: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                shippingControl: null,
            };
        },

        watch: {
            value(to) {
                if (to && this.shippingControl === null) {
                    this.fetchFreeShipping();
                }
            },
        },

        created() {
            EventBus.$on('open-shipping-rules', this.showModal);
        },

        beforeDestroy() {
            EventBus.$on('open-shipping-rules', this.showModal);
        },

        methods: {
            showModal() {
                if (!this.listenEvent) {
                    return;
                }

                this.$emit('input', true);
            },

            fetchFreeShipping() {
                this.requests++;
                shippingService
                    .query()
                    .then((response) => {
                        this.shippingControl = response.data.data;
                    })
                    .catch(() => {
                        console.log('Erro ao carregar');
                    })
                    .then(() => {
                        this.requests--;
                    });
            },
        },
    };
</script>
