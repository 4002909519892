<template>
    <div class="text-center">
        <template v-if="fastDelivery">
            <template v-if="hasSomeProduction">
                <p class="text-bold">Modifique sua sacola e receba seu pedido até o Natal!</p>
                <p style="font-size: 0.95rem">
                    Um ou mais produtos escolhidos estão alongando o prazo de envio.<br />
                    Remova os produtos com prazos posteriores a 23/12.
                </p>
            </template>
            <template v-else>
                <p class="text-bold">Seus produtos chegam antes do Natal</p>
                <p style="font-size: 0.95rem">Compre com tranquilidade!</p>
            </template>
        </template>
        <template v-else>
            <p class="text-bold">Natal com Chico Rei</p>
            <p style="font-size: 0.95rem">
                Saiba se seus produtos chegam antes do Natal!<br />
                <a @click="openFastDelivery" class="text-underline">Clique Aqui</a>
            </p>
        </template>
    </div>
</template>

<script>
    import { EventBus } from '../../../common/common';

    export default {
        name: 'CartFastDelivery',

        props: ['products', 'fastDelivery'],

        data() {
            return {};
        },

        computed: {
            hasSomeProduction() {
                if (!this.products) {
                    return false;
                }

                for (let i = 0; i < this.products.length; i++) {
                    if (
                        (this.products[i].product.type || {}).url !== 'vale-presente' &&
                        (this.products[i].product.type || {}).url !== 'assinatura' &&
                        this.products[i].product.virtual_stock &&
                        this.products[i].quantity > this.products[i].in_stock &&
                        this.products[i].has_quantity
                    ) {
                        return true;
                    }
                }

                return false;
            },
        },

        methods: {
            openFastDelivery() {
                EventBus.$emit('fast-delivery-edit');
            },
        },
    };
</script>
