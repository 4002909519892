<template>
    <FieldWrapper
        :id="innerId"
        :label="label"
        :tooltip="tooltip"
        :hint="hint"
        :error="error || innerError"
        :add-on-before="addOnBefore"
        :add-on-after="addOnAfter"
    >
        <input
            :id="innerId"
            ref="inputFieldRef"
            :autocomplete="autocomplete"
            :class="getInputClasses"
            :disabled="disabled"
            :max="max"
            :maxlength="maxlength"
            :min="min"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :step="step"
            :value="modelValue"
            type="number"
            @input="onInput"
            @change="onChange"
            @keyup.enter="$emit('enter')"
        />
    </FieldWrapper>
</template>

<script>
    import Field from '../field/Field';
    import FieldWrapper from '../field-wrapper/FieldWrapper';

    export default {
        compatConfig: { COMPONENT_V_MODEL: false },

        name: 'NumberField',

        extends: Field,

        components: { FieldWrapper },

        props: {
            min: {
                type: Number,
                default: null,
            },
            max: {
                type: Number,
                default: null,
            },
            step: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                innerError: null,
            };
        },

        watch: {
            modelValue() {
                this.onChange();
            },
        },

        methods: {
            onInput(event) {
                if (this.error && this.autoCleanErrors) {
                    this.$emit('update:error', null);
                }

                this.$emit('update:modelValue', parseFloat(event.target.value || this.min || 0));
            },

            onChange(event) {
                let value = event ? parseFloat(event.target.value || this.min || 0) : this.modelValue;

                if (value || value === 0) {
                    if (this.max !== null && value > this.max) {
                        value = null;
                        if (event) event.target.value = null;
                        this.innerError = `O valor máximo é ${this.max}`;
                        this.$emit('update:modelValue', value);
                    } else if (this.min !== null && value < this.min) {
                        value = null;
                        if (event) event.target.value = null;
                        this.innerError = `O valor mínimo é ${this.min}`;
                        this.$emit('update:modelValue', value);
                    } else {
                        this.innerError = null;
                        if (event) this.$nextTick(() => (event.target.value = value));
                    }
                }

                this.$emit('change', value);
            },
        },
    };
</script>
