<template>
    <div id="cart-empty" class="mt">
        <div class="text-center mt-30">
            <p>Sua sacola <strong>está vazia.</strong></p>
            <template v-if="discount">
                <div class="frame-support">
                    <p
                        v-html="
                            $t('_cart.discountXWillBeAppliedValueYHtml', [
                                discount.name,
                                discountPrice(discount),
                            ])
                        "
                    >
                        <template v-if="this.discount.date_to">
                            ({{ $t('expiration') }}:
                            <span class="text-bold">{{ discount.date_to }}</span
                            >)
                        </template>
                        <span v-if="discount.type_id === 0">
                            ({{ $t('_shop._discount.notCumulativePromos') }})</span
                        >
                    </p>
                </div>
            </template>
        </div>
        <div v-if="$scope.IS_REGULAR_CUSTOMER" class="mt-30 text-center">
            <h2><i class="icon-cr icon-tag"></i></h2>
            <p>Aproveite o momento</p>
            <a href="/promo"><p class="text-underline">Veja os produtos com desconto hoje</p></a>
        </div>
        <div class="mt-30">
            <div class="text-center">
                <h2>
                    <i class="icon-cr icon-wishlist"></i>
                </h2>
                <p class="text-center">Aproveite os itens que você já ama</p>
                <a href="/minha-conta/wishlist"
                    ><p class="text-underline">Ver minha lista de desejos</p></a
                >
            </div>
        </div>
        <div class="mt-30">
            <div class="text-center">
                <h2>
                    <i class="icon-cr icon-carrier-car"></i>
                </h2>
                <p v-if="$scope.IS_REGULAR_CUSTOMER">
                    Frete grátis a partir de R$155* <br />
                    <span
                        >(<a
                            class="advantages-content text-underline"
                            @click="showRegulamento = true"
                            >Consulte condições</a
                        >)</span
                    >
                </p>
                <p v-if="!$scope.IS_REGULAR_CUSTOMER">
                    Enviamos seu pedido em velocidade de foguete!
                </p>
            </div>
        </div>
        <div class="mt-30 text-center">
            <h3>
                <i class="icon-cr icon-exchange" v-if="$scope.IS_REGULAR_CUSTOMER"></i>
                <i class="icon-cr icon-credit-card" v-if="!$scope.IS_REGULAR_CUSTOMER"></i>
            </h3>
            <p>
                <strong v-if="$scope.IS_REGULAR_CUSTOMER"
                    >Sua primeira troca conosco é grátis</strong
                >
                <strong v-if="!$scope.IS_REGULAR_CUSTOMER">Pagamento Facilitado</strong>
            </p>
            <p v-if="$scope.IS_REGULAR_CUSTOMER">
                Faça a sua primeira compra na Chico Rei com toda a tranquilidade
            </p>
            <p v-if="!$scope.IS_REGULAR_CUSTOMER">
                Parcele em até 4x com cartao de crédito ou boleto bancário
            </p>
        </div>

        <ShippingRules v-model="showRegulamento" />
    </div>
</template>

<script>
    import CartRecommendations from './recommendation/CartRecommendations';
    import { discountService } from '../../../common/service/resource';
    import { readCookie } from '../../../common/common';
    import { shippingService } from '../../../common/service/resource';
    import Modal from '../common/container/Modal.vue';
    import Spinner from '../../../../../submodules/src/components/feedback/spinner/Spinner.vue';
    import ShippingRules from '../product/ShippingRules.vue';

    export default {
        components: { ShippingRules, Spinner, Modal, CartRecommendations },

        props: ['recommendationsTitle', 'discount'],

        data() {
            return { showRegulamento: false };
        },

        methods: {
            discountPrice() {
                let type = '';

                if (this.discount.type_id == 2 && this.discount.value > 0) {
                    type += ' dando um desconto de ' + this.$f.formatMoney(this.discount.value);
                } else if (this.discount.type_id == 1 && this.discount.value > 0) {
                    type += ' dando um desconto de ' + parseInt(this.discount.value) + '%';
                }

                if (this.discount.frete_gratis) {
                    type += (this.discount.value > 0 ? ' +' : ' gerando') + ' Frete Grátis';
                    if (this.discount.cep_frete_gratis) {
                        type += 'para o CEP' + this.discount.cep_frete_gratis;
                    }
                }

                return type;
            },
        },
    };
</script>
