<template>
    <div id="product-variations">
        <slot name="select-size-error" v-if="!product.is_clothing"></slot>
        <div v-if="!product.is_clothing && sizes && sizes.length > 1" class="product-combo-select">
            <SelectField
                v-if="isPhone"
                v-model="selectedPhoneModel"
                :label="$t('_product.manufacturer')"
            >
                <option :value="null">
                    {{ $t('_product.selectManufacturer') }}
                </option>
                <option v-for="model in phoneModels" :value="model">
                    {{ model }}
                </option>
            </SelectField>
            <SelectField
                :disabled="isPhone && !selectedPhoneModel"
                :label="label ? label : isPhone ? $t('_product.phoneModel') : ''"
                class="mt-3"
                :model-value="selectedVariation"
                @change="$emit('input', $event)"
            >
                <option v-if="isPhone" :value="null">
                    {{ $t('_product.selectPhoneModel') }}
                </option>
                <option v-else :value="null">
                    {{ $t('_product.selectSize') }}
                </option>
                <option
                    v-for="item in selectOptions"
                    :disabled="
                        shopMode &&
                        (!product.in_stock || (item.quantity === 0 && !item.virtual_stock))
                    "
                    :value="item"
                >
                    {{ item.size_name }}
                    <template v-if="!reprintMode">
                        <span
                            v-if="!product.in_stock || (!item.virtual_stock && item.quantity === 0)"
                            >&nbsp;-&nbsp;{{ $t('_shop._stock.soldOut') }}</span
                        >
                        <span v-else-if="!item.virtual_stock && item.quantity === 1"
                            >&nbsp;-&nbsp;{{ $tc('_shop._stock.remainX', item.quantity) }}</span
                        >
                        <span v-else-if="!item.virtual_stock && item.quantity < 4"
                            >&nbsp;-&nbsp;{{ $tc('_shop._stock.remainX', item.quantity) }}</span
                        >
                        <span v-if="product.price_min > 0">
                            &nbsp;-&nbsp;
                            <span
                                v-if="item.price_old"
                                v-html="
                                    $t('_shop._price.fromToHtml', [
                                        $f.formatMoney(item.price_old),
                                        $f.formatMoney(item.price),
                                    ])
                                "
                            />
                            <span v-else-if="item.price">{{ $f.formatMoney(item.price) }}</span>
                            <span v-else>{{ $f.formatMoney(product.price_old) }}</span>
                        </span>
                    </template>
                </option>
            </SelectField>
        </div>
        <div v-else class="text-yanone">
            <p v-if="label" class="mt-0">{{ label }}</p>

            <div class="product-variations-types product-combo-select" style="line-height: 1rem">
                <template v-if="modelings && originalModelingsCount === 1">
                    <div class="inline-block inline-fast">
                        <p class="h6 flex-child-fixed title-infos">
                            {{ $t('_product.model') }}:
                            <span class="text-bold text-no-bold-mobile">{{
                                modelings[0].name
                            }}</span>
                        </p>
                    </div>
                </template>
                <template v-else-if="modelings && modelings.length > 0">
                    <div :class="[isBasicKit ? '' : 'mt']" style="align-self: center">
                        <SelectField @change="setModeling" :model-value="selectedModelId">
                            <option :value="null">Selecione a modelagem</option>
                            <option
                                v-for="modeling in modelings"
                                :value="modeling.id"
                                :disabled="!modeling.has_any"
                            >
                                {{ modeling.url ?? modeling.name }}
                                <span v-if="!modeling.has_any"> (Esgotado)</span>
                            </option>
                        </SelectField>
                    </div>
                    <br />
                </template>
                <template v-if="sizes && (sizes.length > 1 || product.is_clothing)">
                    <div style="align-self: center">
                        <SelectField @change="setSize" :model-value="selectedSizeId">
                            <option :value="null">
                                {{ $t('_product.selectSize') }}
                            </option>
                            <option
                                v-for="size in sizes"
                                :value="size.size_id"
                                :disabled="!size.has_any"
                            >
                                {{ size.size_name }} <span v-if="!size.has_any"> (Esgotado)</span>
                            </option>
                        </SelectField>
                    </div>
                    <br />
                </template>
                <template
                    v-if="fabrics && (fabrics.length > 1 || (!isBasicKit && fabrics.length > 0))"
                >
                    <div class="pos-relative" style="align-self: center">
                        <SelectField
                            class="product-combo-selector"
                            @change="setFabric"
                            :model-value="selectedFabricId"
                        >
                            <option :value="null">Selecione a cor</option>

                            <option
                                v-for="fabric in fabrics"
                                :value="fabric.fabric_id"
                                :disabled="!fabric.has_any"
                            >
                                {{ fabric.product_color_name }}
                                <span v-if="!fabric.has_any"> (Esgotado)</span>
                            </option>
                        </SelectField>
                        <div
                            class="desktop-badge hidden-sm-down"
                            v-if="selectedFabric"
                            :style="[
                                selectedFabric.product_color_hexa.toLowerCase() === '#ffffff'
                                    ? { border: 'solid 1px' }
                                    : { border: 'none' },
                                selectedFabric.product_color_hexa
                                    ? { background: selectedFabric.product_color_hexa }
                                    : {},
                            ]"
                        >
                            <span class="color-badge-desktop d-block"></span>
                        </div>
                        <div
                            class="mobile-badge hidden-sm-up"
                            v-if="selectedFabric"
                            :style="[
                                selectedFabric.product_color_hexa.toLowerCase() === '#ffffff'
                                    ? { border: 'solid 1px' }
                                    : { border: 'none' },
                                selectedFabric.product_color_hexa
                                    ? { background: selectedFabric.product_color_hexa }
                                    : {},
                            ]"
                        >
                            <span class="color-badge-mobile d-block"></span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectField from '../../../../../submodules/src/components/input/select-field/SelectField.vue';
    import Alert from '../common/Alert';
    import DeliverReadyInfoModal from './DeliverReadyInfoModal';
    import ProductVariationsFabrics from './ProductVariationsFabrics.vue';
    import BaseVariations from './BaseVariations.vue';

    export default {
        name: 'ProductSelectVariations',

        extends: BaseVariations,

        props: {
            isBasicKit: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            ProductVariationsFabrics,
            DeliverReadyInfoModal,
            SelectField,
            Alert,
        },

        data() {
            return {};
        },

        computed: {
            selectedModel() {
                return this.selectedModelId
                    ? (this.modelings || []).find((m) => m.id === this.selectedModelId)
                    : null;
            },
        },

        watch: {
            selectedModel(to) {
                if (to && !to.has_any) {
                    this.setModeling(null);
                }
            },
            selectedSize(to) {
                if (to && !to.has_any) {
                    this.setSize(null);
                }
            },
            selectedFabric(to) {
                if (to && !to.has_any) {
                    this.setFabric(null);
                }
            },
        },

        created() {
            this.isMobile = false;
            this.shouldEmitEvent = false;

            if (this.modelings && this.modelings.length > 0) {
                let model = this.modelings.find((m) => m.has_any);
                model = model || this.modelings[0];

                if (model) {
                    this.setModeling(model.id);
                }
            }

            if (!this.selectedModelId && this.modelings.length === 1 && this.product.is_clothing) {
                this.setModeling(this.modelings[0].id);
            }

            if (!this.selectedSizeId && this.sizes.length === 1 && !this.product.is_clothing) {
                this.setSize(this.sizes[0].size_id);
            }

            if (!this.selectedFabricId && this.fabrics && this.fabrics.length > 0) {
                this.setFabric(this.fabrics[0].fabric_id);
            }
        },

        methods: {},
    };
</script>
