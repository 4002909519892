var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ busy: _vm.loadingShipping }},[(_vm.$scope.IS_REGULAR_CUSTOMER)?_c('div',[(_vm.cart && !_vm.cart.is_only_virtual_products)?_c('FreeShippingProgress',{staticClass:"mb-3",attrs:{"cart":_vm.cart,"is-lateral-menu":_vm.isLateralMenu,"is-mobile":_vm.isMobile,"loading":_vm.loading || _vm.loadingShipping,"national-shipping":_vm.nationalShipping,"calculated-shipping-obj":_vm.freeShippingCalcObj,"mini":true}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{class:{ 'shake-input': _vm.shakeInput }},[_c('Alert',{attrs:{"alert":_vm.alert}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-align-center"},[(_vm.nationalShipping)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cep),expression:"cep"},{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],ref:"cepInput",staticClass:"text-uppercase input-cart input-btn-internal placeholder-AB",attrs:{"type":"text","placeholder":_vm.PlaceHolderShipping,"id":"cep","maxlength":"9","pattern":"\\d*","disabled":_vm.loadingShipping || (!_vm.nationalShipping && !_vm.country)},domProps:{"value":(_vm.cep)},on:{"blur":function($event){_vm.$emit('blur');
                    _vm.PlaceHolderShipping = 'Calcular Frete e Prazo';},"focus":function($event){_vm.$emit('focus');
                    _vm.PlaceHolderShipping = 'Digite seu Cep';},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.calculateShipping(null)},"input":function($event){if($event.target.composing)return;_vm.cep=$event.target.value}}}):_c('label',{staticClass:"cr-select cr-select-country"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],attrs:{"id":"country","disabled":_vm.loadingShipping},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.country=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.country && !_vm.nationalShipping ? _vm.calculateShipping(null, true) : null}]}},[_c('option',{attrs:{"selected":""}},[_vm._v("Selecione o país")]),_vm._v(" "),_vm._l((_vm.countries),function(country){return _c('option',{domProps:{"value":country}},[_vm._v("\n                        "+_vm._s(country.name)+"\n                    ")])})],2)]),_vm._v(" "),(!_vm.nationalShipping)?_c('button',{staticClass:"btn btn-select-country",attrs:{"disabled":_vm.loadingShipping ||
                    (!_vm.nationalShipping && !_vm.country) ||
                    (_vm.nationalShipping && (!_vm.cep || _vm.cep.length < 9))},on:{"click":function($event){return _vm.calculateShipping(null, true)}}},[_vm._v("\n                "+_vm._s(_vm.$t('calculate'))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.nationalShipping)?_c('button',{staticClass:"btn-internal-input-ab",style:([
                    _vm.isLateralMenu ? { height: '46px' } : {},
                    !_vm.isMobile ? { height: '40px' } : {},
                ]),attrs:{"disabled":_vm.loadingShipping || (!_vm.nationalShipping && !_vm.country)},on:{"click":function($event){return _vm.calculateShipping(null)}}},[_c('span',[_vm._v("Calcular")])]):_vm._e()])],1),_vm._v(" "),(!_vm.nationalShipping)?_c('div',{staticClass:"mt"},[_c('a',{staticClass:"text-yanone text-accent text-left cursor-pointer",on:{"click":function($event){return _vm.toggleNationalShipping()}}},[_vm._v("\n            "+_vm._s(_vm.changeShippingTypeLabel)+"\n        ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"collapsible text-yanone opened",class:{ opened: _vm.showShippingDetails && !_vm.showShippingForm }},[_c('ul',{staticClass:"checkout-options mt-2 mb-4",class:{
                hidden:
                    _vm.cepNotFound ||
                    _vm.loadingShipping ||
                    (!_vm.nationalShipping && !_vm.country) ||
                    (_vm.nationalShipping && (!_vm.cep || _vm.cep.length < 9)) ||
                    !_vm.regularShipping.options ||
                    !_vm.regularShipping.options.length,
            }},_vm._l((_vm.filteredShippingOptions),function(option,index){return _c('li',[_c('label',{staticClass:"flex-grid",attrs:{"for":`carrier-${option.carrier_id}`},on:{"click":function($event){return _vm.selectCarrier(option)}}},[_c('div',{staticClass:"checkout-option-content flex-xs-9 flex-md-8"},[_c('div',{staticClass:"mx-0"},[(option.prime)?_c('img',{staticClass:"checkout-option-content-img",attrs:{"src":`${_vm.$scope.CDN_IMAGES}/images/site/2021/prime/selo_prime_02_378bcb.svg`,"alt":"Chico Rei Prime"}}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"my-0"},[_c('span',[_vm._v("("+_vm._s(option.name)+")")]),_vm._v(" "),(
                                        (option.deadline > 0 && option.carrier_id === 4) ||
                                        option.carrier_id === 81027
                                    )?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(
                                            _vm.$t('untilDateXHtml', [
                                                _vm.getIsoFormattedFinalForecast(option.forecast),
                                            ])
                                        )}}),_vm._v(" "),(option.carrier_id === 81027)?_c('span',[_c('br'),_vm._v(_vm._s(option.store.address)+",\n                                        "+_vm._s(option.store.number)+" -\n                                        "),(option.store.complement)?[_vm._v("\n                                            "+_vm._s(option.store.complement)+" -\n                                        ")]:_vm._e(),_vm._v("\n                                        "+_vm._s(option.store.neighborhood)+",\n                                        "+_vm._s(option.store.city)+"\n                                    ")],2):_vm._e()]):(option.deadline > 0 && option.carrier_id !== 3)?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(
                                            _vm.$t('dateToXHtml', [
                                                _vm.getIsoFormattedFinalForecast(option.forecast),
                                            ])
                                        )}})]):_vm._e()])]),_vm._v(" "),(option.expressEnabled && option.actual_additional_price > 0)?_c('p',{},[_vm._v("\n                            "+_vm._s(_vm.$t('_shop.withExpressProduction'))+"\n                        ")]):_vm._e()]),_vm._v(" "),_c('p',{staticClass:"flex-xs-3 flex-md-4 price-carrier text-right"},[(
                                (option.price === 0 || option.prime) &&
                                option.carrier_id !== 3 &&
                                option.actual_additional_price === 0
                            )?_c('span',{staticClass:"free-price"},[_vm._v("\n                            "+_vm._s(_vm.$t('free'))+"\n                        ")]):(option.price + option.actual_additional_price > 0)?_c('span',[_vm._v("\n                            "+_vm._s(_vm.$f.formatMoney(option.price))+"\n                        ")]):_vm._e()])])])}),0),_vm._v(" "),(_vm.enableExpressShipping && _vm.expressShipping.available)?_c('div',{staticClass:"d-flex mt",attrs:{"id":"cart-shipping-express"}},[_c('h4',[_vm._v("\n                Receba seu pedido até "+_vm._s(_vm.expressShipping.days)+" dias antes por mais\n                "+_vm._s(_vm.$f.formatMoney(_vm.expressShipping.price))+".\n                "),(this.expressShipping.obs != null)?_c('span',[_c('br'),_vm._v("* Indisponível para a opção "+_vm._s(_vm.expressShipping.obs))]):_vm._e()]),_vm._v(" "),_c('button',{staticClass:"btn btn-light ml-auto",on:{"click":function($event){return _vm.toggleExpressShipping()}}},[_vm._v("\n                "+_vm._s(_vm.expressShipping.enabled ? 'Desativar' : 'Ativar')+"\n            ")])]):(
                !_vm.hasPickupStore &&
                _vm.showSpecialPriceMessage &&
                !(
                    _vm.cepNotFound ||
                    _vm.loadingShipping ||
                    (!_vm.nationalShipping && !_vm.country) ||
                    (_vm.nationalShipping && (!_vm.cep || _vm.cep.length < 9))
                ) &&
                _vm.regularShipping.options.length > 0
            )?_c('div',{staticClass:"alert-success mt-4"},[_c('p',[_vm._v("\n                Aproveite a oferta de frete reduzido para esta compra e troque seu frete grátis\n                por uma modalidade mais rápida, com desconto! 😉\n            ")])]):_vm._e(),_vm._v(" "),(
                _vm.showMessageInStock &&
                !(
                    _vm.cepNotFound ||
                    _vm.loadingShipping ||
                    (!_vm.nationalShipping && !_vm.country) ||
                    (_vm.nationalShipping && (!_vm.cep || _vm.cep.length < 9))
                ) &&
                _vm.regularShipping.options.length > 0
            )?_c('div',{staticClass:"alert-success mt-2 message-in-stock-cart-test-ab",attrs:{"id":"message-in-stock-cart-test-ab"}},[_vm._m(0)]):_vm._e()]),_vm._v(" "),_c('div',{class:{ hidden: _vm.shippingAdditionalDeadline == null }},[_c('p',[_vm._v("Prazo adicional: "+_vm._s(_vm.shippingAdditionalDeadline)+" dias úteis")]),_vm._v(" "),_c('p',[_vm._v("\n            Para viabilizarmos uma produção mais consciente e exclusiva, alguns produtos de seu\n            pedido serão produzidos após finalizar a sua compra, por isso o prazo adicional\n            acima ;)\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n            O prazo total é de até "+_vm._s(_vm.shippingAdditionalDeadline)+" dias úteis + prazo do frete\n            escolhido.\n        ")])]),_vm._v(" "),_c('div',{staticClass:"alert-danger mt-3",class:{ hidden: !_vm.cepNotFound }},[_c('span',[_vm._v("CEP não encontrado ou indisponível para entrega.")]),_vm._v(" "),_c('a',{attrs:{"href":"http://www.buscacep.correios.com.br/sistemas/buscacep/","rel":"noopener","target":"_blank"}},[_vm._v("\n            Verifique o CEP correto aqui.\n        ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-underline",attrs:{"href":"/vapt-vupt"}},[_c('p',[_vm._v("Precisa de um prazo menor? Confira outros produtos com envio imediato")])])
}]

export { render, staticRenderFns }