<template>
    <div class="hidden-sm-down form-newsletter mr-5">
        <NewsletterSubscriber v-slot="subscriber">
            <img
                :src="`${$scope.CDN_IMAGES}/images/site/home/universal/primeira-compra-rodape-2024.png?auto=compress,format&q=65`"
                alt="Ganhe 10% de desconto na primeira compra"
                class="lazy img-responsive w-full"
                width="260"
                height="110"
            />
            <form id="footer-newsletter" :class="{ busy: subscriber.busy }" class="w-full">
                <label>Cadastre-se em nossa newsletter:</label>
                <TextField
                    class="input-newsletter"
                    id="newsletter-footer-nome"
                    v-model="subscriber.fields.nome"
                    autocomplete="name"
                    placeholder="nome:"
                    type="name"
                />
                <TextField
                    class="mt-2 input-newsletter"
                    id="newsletter-footer-email"
                    v-model="subscriber.fields.email"
                    autocomplete="email"
                    placeholder="e-mail:"
                    type="email"
                />
                <LoadingButton
                    :loading="subscriber.busy"
                    class="btn btn-lg btn-wide mt-3 text-bold"
                    @click="subscriber.subscribe"
                >
                    QUERO PARTICIPAR!
                </LoadingButton>
            </form>
        </NewsletterSubscriber>
    </div>
</template>

<script>
    import LoadingButton from '../common/LoadingButton';
    import NewsletterSubscriber from '../newsletter/NewsletterSubscriber';
    import TextField from '../../../../../submodules/src/components/input/text-field/TextField.vue';

    export default {
        el: '#footer-newsletter-vue',
        name: 'FooterNewsletter',
        components: { TextField, LoadingButton, NewsletterSubscriber },
    };
</script>
