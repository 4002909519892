<template>
    <div>
        <div ref="alertAnchor" class="anchor"></div>
        <Alert :alert="alert" />

        <form @submit.prevent="login" class="side-login">
            <div class="d-flex flex-justify-center">
                <div class="flex-grid">
                    <div class="mt flex-xs-7 flex-sm-7 flex-md-7 flex-lg-7">
                        <span
                            class="h5 text-center my-auto mx flex-child-fixed"
                            style="font-family: Roboto"
                            >Acesse com:</span
                        >
                    </div>
                    <div class="flex-xs-2 flex-sm-2 flex-md-2 flex-lg-2">
                        <div
                            id="g_id_onload"
                            data-client_id="316338802025-ck2s8vosc6arsqg34hb272nl7c6q99d8.apps.googleusercontent.com"
                            data-context="signin"
                            data-ux_mode="popup"
                            data-callback="onGoogleSignIn"
                            data-nonce=""
                            data-auto_prompt="false"
                        ></div>

                        <div
                            class="g_id_signin"
                            data-type="icon"
                            data-shape="square"
                            data-theme="outline"
                            data-text="signin_with"
                            data-size="large"
                        ></div>
                    </div>
                    <div class="flex-xs-1 flex-sm-1 flex-md-1 flex-lg-2 ml-3">
                        <span class="facebook-login text-center" @click="fbLogin">
                            <div class="facebook-icon">
                                <img
                                    data-src="https://chico-rei.imgix.net/icones/icon-facebook.jpg"
                                />
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-justify-center mb-3 mt-3">
                <span class="text-or">OU:</span>
            </div>

            <TextField
                v-model="email"
                :error.sync="errors.email"
                autocomplete="email"
                autofocus
                name="email"
                placeholder="E-MAIL"
                type="email"
                @blur="validateEmail"
            />
            <TextField
                class="mt-3 mb-3"
                v-model="password"
                :error.sync="errors.password"
                autocomplete="password"
                name="password"
                placeholder="SENHA"
                type="password"
                @blur="validatePassword"
            />
            <LoadingButton :loading="submitting" class="btn button-login text-login" @click="login">
                Entrar <span><i class="animation-go-back icon-cr icon-arrow-right"></i></span>
            </LoadingButton>

            <input :value="backUrl" class="hidden" name="back" type="hidden" />
            <input
                id="SubmitLogin"
                class="button"
                name="SubmitLogin"
                type="hidden"
                value="Log in"
            />

            <div class="d-flex flex-justify-center mt-4">
                <a class="text-underline text-password" href="/recuperar-senha/">
                    Esqueci a senha
                </a>
            </div>

            <div class="d-flex flex-justify-center mt-4">
                <label class="text-register"> Não tem cadastro?</label>
                <a
                    class="text-register ml-3"
                    :disabled="submitting"
                    :href="`/cadastrar${backUrl ? '?back=' + backUrl : ''}`"
                >
                    <strong>Cadastre-se</strong>
                </a>
            </div>
        </form>
    </div>
</template>

<script>
    import Alert from '../common/Alert';
    import { initializeSocialSignIn } from '../../../common/cadastro';
    import { readUrlQuery } from '../../../common/common';
    import cr$ from '../../../common/crquery';
    import LoadingButton from '../common/LoadingButton';
    import TextField from '../../../../../submodules/src/components/input/text-field/TextField.vue';

    export default {
        name: 'SideLogin',

        components: { LoadingButton, TextField, Alert },

        data() {
            return {
                backParam: readUrlQuery('back'),

                email: '',
                password: '',

                errors: {
                    email: null,
                    password: null,
                },

                alert: null,

                submitting: false,
            };
        },

        mounted() {
            initializeSocialSignIn();

            this.$nextTick(() => {
                if (window.gapi) {
                    window.gapi.signin2.render('google-sign-in-button');
                }
            });
        },

        watch: {
            alert() {
                cr$.byEl(this.$refs.alertAnchor).scrollIntoView();
            },
        },

        computed: {
            backUrl() {
                return this.$scope.BACK_URL ? this.$scope.BACK_URL : this.backParam;
            },
        },

        methods: {
            isEmailValid() {
                return /^[a-z0-9!#$%&'*+\/=?^`{}|~_-]+[.a-z0-9!#$%&'*+\/=?^`{}|~_-]*@[a-z0-9]+[._a-z0-9-]*\.[a-z0-9]+$/iu.test(
                    this.email
                );
            },

            validateEmail() {
                if (this.email.trim().length === 0) {
                    this.errors.email = 'Informe seu email';
                } else if (!this.isEmailValid(this.email)) {
                    this.errors.email = 'Email inválido';
                }
            },

            validatePassword() {
                if (this.password.trim().length === 0) {
                    this.errors.password = 'Informe sua senha';
                } else if (this.password.trim().length > 254) {
                    this.errors.password = 'Senha muito longa';
                } else if (this.password.trim().length < 6) {
                    this.errors.password = 'Senha muito curta';
                }
            },

            validate() {
                this.validateEmail();
                this.validatePassword();
                return !(this.errors.email || this.errors.password);
            },

            showError(message) {
                this.alert = {
                    message:
                        message ||
                        'Não foi possível realizar o login. Verifique seu email e senha.',
                    isError: true,
                };
            },

            login() {
                if (this.validate()) {
                    this.submitting = true;

                    const currentUrl = new URL(window.location.href);
                    const realBackUrl = (currentUrl.pathname || '').substring(1);
                    const backParam = currentUrl.searchParams.get('back');

                    let payload = new FormData();
                    payload.append('email', this.email);
                    payload.append('password', this.password);

                    let url = '/login.php';
                    if (this.backUrl || (realBackUrl && realBackUrl !== '')) {
                        url +=
                            '?back=' +
                            (backParam && backParam !== ''
                                ? backParam
                                : realBackUrl && realBackUrl !== ''
                                ? realBackUrl
                                : this.backUrl);
                    }

                    this.axios.http
                        .post(url, payload)
                        .then((response) => {
                            if (response.data.success) {
                                window.location.href =
                                    response.data.redirectTo || '/minha-conta/pedidos';
                            } else {
                                this.showError();
                            }
                        })
                        .catch(() => this.showError())
                        .then(() => (this.submitting = false));
                }
            },

            fbLogin() {
                if (!window.FB) {
                    this.showError('Erro ao efetuar login via Facebook. Tente novamente.');
                    return;
                }

                window.FB.login(
                    function (response) {
                        if (response.authResponse) {
                            window.onFacebookSignIn(response);
                        } else {
                            console.log('User cancelled login or did not fully authorize.');
                        }
                    },
                    {
                        scope: 'public_profile,email',
                    }
                );
            },
        },
    };
</script>
