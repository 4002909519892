var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:[_vm.isHome ? 'countdown-2' : 'countdown']},[(_vm.isHome)?_c('div',{staticClass:"promo-text-ab2-mobile hidden-xs-up"},[_vm._v("\n        "+_vm._s(_vm.alt)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.isHome)?_c('div',{staticClass:"countdown-container"},[_c('div',{staticClass:"countdown-text",staticStyle:{"color":"white","align-content":"flex-end"},style:('color: ' +
                (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                    ? _vm.$scope.active_counter[0].counter_color
                    : '') +
                ' !important')},[_vm._v("\n            Acaba em:\n        ")]),_vm._v(" "),_c('div',[(_vm.showDays && _vm.days)?_c('span',{staticClass:"days",style:('color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_color
                        : '') +
                    ' !important')},[_c('span',[_vm._v(_vm._s(_vm.days))])]):_vm._e(),_vm._v(" "),(_vm.showDays && _vm.days)?_c('span',{staticClass:"days-letter",style:('background-color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_background_color
                        : '') +
                    ' !important; color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_color
                        : '') +
                    ' !important')},[_vm._v("d")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"hours",style:('color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_color
                        : '') +
                    ' !important')},[_c('span',[_vm._v(_vm._s(_vm.hours.toString().split('')[0]))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.hours.toString().split('')[1]))])]),_vm._v(" "),_c('span',{staticClass:"hours-letter",style:('background-color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_background_color
                        : '') +
                    ' !important; color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_color
                        : '') +
                    ' !important')},[_vm._v("h")]),_vm._v(" "),_c('span',{staticClass:"minutes",style:('color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_color
                        : '') +
                    ' !important')},[_c('span',[_vm._v(_vm._s(_vm.minutes.toString().split('')[0]))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.minutes.toString().split('')[1]))])]),_vm._v(" "),_c('span',{staticClass:"hours-letter",style:('background-color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_background_color
                        : '') +
                    ' !important; color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_color
                        : '') +
                    ' !important')},[_vm._v("m")]),_vm._v(" "),_c('span',{staticClass:"seconds",style:('color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_color
                        : '') +
                    ' !important')},[_c('span',[_vm._v(_vm._s(_vm.seconds.toString().split('')[0]))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.seconds.toString().split('')[1]))])]),_vm._v(" "),_c('span',{staticClass:"seconds-letter",style:('background-color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_background_color
                        : '') +
                    ' !important; color: ' +
                    (_vm.$scope.active_counter && _vm.$scope.active_counter.length > 0
                        ? _vm.$scope.active_counter[0].counter_color
                        : '') +
                    ' !important')},[_vm._v("s")])])]):_c('div',[(_vm.showDays && _vm.days)?_c('span',{staticClass:"days"},[_vm._v(_vm._s(_vm.days))]):_vm._e(),_vm._v(" "),(_vm.showDays && _vm.days)?_c('span',{staticClass:"days-letter"},[_vm._v("d")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"hours"},[_c('span',[_vm._v(_vm._s(_vm.hours.toString().split('')[0]))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.hours.toString().split('')[1]))])]),_vm._v(" "),_c('span',{staticClass:"hours-letter"},[_vm._v("h")]),_vm._v(" "),_c('span',{staticClass:"minutes"},[_c('span',[_vm._v(_vm._s(_vm.minutes.toString().split('')[0]))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.minutes.toString().split('')[1]))])]),_vm._v(" "),_c('span',{staticClass:"hours-letter"},[_vm._v("m")]),_vm._v(" "),_c('span',{staticClass:"seconds"},[_c('span',[_vm._v(_vm._s(_vm.seconds.toString().split('')[0]))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.seconds.toString().split('')[1]))])]),_vm._v(" "),_c('span',{staticClass:"hours-letter"},[_vm._v("s")])]),_vm._v(" "),(_vm.isHome)?_c('div',{staticClass:"promo-button-ab2-mobile hidden-xs-up"},[_vm._v("Confira")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }