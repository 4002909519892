export default () => ({
    pt: {
        _shop: {
            toBuy: 'Comprar',
            addToCart: 'Adicionar à sacola',
            priceFrom: 'A partir de',
            priceTo: 'Por',
            _price: {
                from: 'A partir de',
                to: 'Por',
                fromToHtml: 'De <del>{0}</del> por {1}',
                upToXTimesInterestFree: 'Até {0}x sem juros',
                upToXTimesInterestFreeMobile: 'Até {0}x',
            },
            _advantages: {
                freeShippingTitle: 'Frete Grátis',
                freeShipping: 'Leve agora com frete grátis!',
                freeShippingAnyPurchase: 'Frete grátis em qualquer compra. Aproveite!',
                freeShippingAbove: 'Frete grátis a partir de {0}',
                freeFirstExchangeTitle: 'Troca Grátis',
                freeFirstExchange: 'Sua 1ª troca no site é grátis',
            },
            _labels: {
                vegan: 'Produto Vegano',
            },
            _wishlist: {
                add: 'Adicionar à lista de desejos',
                successfullyAddedHtml:
                    'Produto adicionado com sucesso.<strong><a href="{link}">Clique aqui</a></strong> para ver os produtos da sua lista de desejos.',
                stockWarning:
                    'Assim que o produto estiver disponível novamente em estoque te avisaremos por email! :)',
                selectSize: 'Selecione o tamanho que deseja adicionar em sua lista de desejos:',
            },
            yourBag: 'Resumo do pedido',
            toViewBag: 'Ver sacola',
            discounts: 'Cupom / Vale-presente',
            total: 'Total',
            youHaveNotAddedItems: 'Você ainda não adicionou itens à sua sacola',
            toFinishOrder: 'Finalizar compra',
            toKeepBuying: 'Continuar comprando',
            giftOrder: 'Pedido para presente?',
            giftOrderOpen: 'É presente? Adicione embalagens especiais',
            giftOrderShort: 'Para presente ?',
            deliveryOutside: 'Entrega fora do Brasil?',
            yourDeliveryOutside: 'Sua entrega é fora do Brasil?',
            productsInYourBagX: '+{n} produto em sua sacola | +{n} produtos em sua sacola',
            subtotal: 'Subtotal',
            items: 'Nenhum item | {n} item | {n} itens',
            myBag: 'Minha sacola',
            minimumValueNotReached: 'Pedido mínimo não atingido',
            _prime: {
                getForFreeShipping:
                    'Adquira o Chico Rei Prime para desfrutar de frete grátis por 1 ano em todos os pedidos, sem valor mínimo.',
                gotFreeShipping:
                    'Pronto, você está prestes a adquirir frete grátis ilimitado por 1 ano!',
                dontWantSign: 'Não quero assinar',
            },
            expressProduction: 'Produção Expressa',
            onDemandProduction: 'Sob Encomenda',
            withExpressProduction: 'Com Produção Expressa',
            _discount: {
                enter: 'Digite o código do cupom / vale-presente',
                notCumulativePromos: 'Desconto não cumulativo com outros cupons ou promoções',
                code: 'Código do cupom',
            },
            _stock: {
                soldOut: 'Esgotado',
                remainX: 'Rest. {n} | Rest. {n}',
                inStock: 'Pronta Entrega',
                lastUnit: 'Última unidade!',
                lastUnits: 'Últimas unidades!',
                qtyUnavailable: 'Quantidade não disponível',
            },
            itemsOnDemandX: '{n} item sob demanda | {n} itens sob demanda',
            selectWhatAddPurchase: 'Selecione o que deseja adicionar à sua sacola:',
            interestFree: 'sem juros',
            installmentsX: '{n} parcela | {n} parcelas',
            inCash: 'à vista',
            creditCard: 'Cartão de Crédito',
            bankBillet: 'Boleto Bancário',
            bankDeposit: 'Transferência Bancária',
            paymentLink: 'Link de Pagamento',
            closeBag: 'Fechar sacola',
        },
    },
    en: {
        _shop: {
            toBuy: 'Buy',
            addToCart: 'Add to cart',
            _price: {
                from: 'From {0}',
                to: 'For',
                fromToHtml: 'From <del>{0}</del> for {1}',
                upToXTimesInterestFree: 'Up to {0}x {1} interest-free',
            },
            _advantages: {
                freeShippingTitle: 'Free shipping!',
                freeShipping: 'Buy now with free shipping!',
                freeShippingAnyPurchase: 'Free shipping in any purchase. Enjoy!',
                freeShippingAbove: 'Free shipping in orders above {0}',
                freeFirstExchange: 'Your first exchange is free',
                freeFirstExchangeTitle: 'Exchange free',
            },
            _labels: {
                vegan: 'Vegan Product',
            },
            _wishlist: {
                add: 'Add to Wishlist',
                successfullyAddedHtml:
                    'Product successfully added. <strong> <a href="{link}"> Click here </a> </strong> to see the products on your wishlist.',
                stockWarning:
                    'As soon as the product is available again in stock we will notify you by email! :)',
                selectSize: 'Select the size you want to add to your wishlist:',
            },
            errorAddProductCart: 'Não foi possível adicionar este produto à sua sacola. ',
            yourBag: 'Your bag',
            toViewBag: 'See bag',
            discounts: 'Coupon /Gift-voucher',
            total: 'Total',
            youHaveNotAddedItems: 'You have not yet added items to your purchase',
            toFinishOrder: 'Finish order',
            toKeepBuying: 'Keep Buying',
            giftOrder: 'Is the order for a gift?',
            giftOrderShort: 'Is the order for a gift?',
            deliveryOutside: 'Is delivery outside of Brazil?',
            yourDeliveryOutside: 'Is delivery outside of Brazil?',
            productsInYourBagX: '+{n} product in your bag | {n} products in your bag',
            subtotal: 'Subtotal',
            items: 'No items | {n} item | {n} items',
            myPurchase: 'My purchase',
            minimumValueNotReached: 'Minimum value not met',
            _prime: {
                getForFreeShipping:
                    'Get Chico Rei Prime to enjoy free shipping for 1 year on all orders, with no minimum value.',
                gotFreeShipping: "You're about to purchase unlimited free shipping for 1 year!",
                dontWantSign: "I don't want to sign",
            },
            expressProduction: 'Express Production',
            onDemandProduction: 'On Demand Production',
            _discount: {
                enter: 'Enter the discount coupon',
                notCumulativePromos: 'Discount not cumulative with other coupons or promotions',
                code: 'Discount coupon code',
            },
            _stock: {
                soldOut: 'Sold out',
                remainX: '{n} remaining',
                inStock: 'In stock',
                lastUnit: 'Last item! | Last {n} items!',
                lastUnits: 'Last item! | Last {n} items!',
                qtyUnavailable: 'Quantity not available',
            },
            itemsOnDemandX: '{n} item on demand | {n} items on demand',
            selectWhatAddPurchase: 'Select what you want to add to your purchase:',
            interestFree: 'interest free',
            installmentsX: '{n} installment | {n} installments',
            inCash: 'in cash',
            creditCard: 'Credit Card',
            bankBillet: 'Billet',
            bankDeposit: 'Bank Deposit',
            paymentLink: 'Payment Link',
            closeBag: 'Close bag',
        },
    },
});
