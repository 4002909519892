<template>
    <div :class="{ busy: cartIsBusy }">
        <template v-if="hasVariations">
            <template v-if="canBeBought">
                <template v-if="!$scope.IS_REGULAR_CUSTOMER && (product.type || {}).id === 15">
                    <ProductWholesalerSingleSize
                        v-model="wholesalerSizesQuantitiesToBuy"
                        :product="product"
                    />
                </template>
                <template v-else-if="!$scope.IS_REGULAR_CUSTOMER">
                    <ProductWholesalerMultipleSizes
                        v-model="wholesalerSizesQuantitiesToBuy"
                        :product="product"
                        class="mb"
                        @show-sizes-table="$emit('show-sizes-table')"
                    />
                </template>
                <template v-else>
                    <ProductVariations
                        class="ad"
                        v-model="sizeToBuy"
                        :product="product"
                        :shop-mode="true"
                        :show-reprint-message="hasStockAvailable && showReprintMessage"
                        @reprint="showReprintModalForSelectedSize"
                    />
                </template>
            </template>

            <Alert v-if="alert" :alert="alert" />

            <div v-if="!canBeBought" class="alert-danger mb text-center">Produto Esgotado</div>
            <div v-else-if="hasStockAvailable">
                <div
                    id="product-buy-button-container"
                    :style="$scope.IS_REGULAR_CUSTOMER ? null : 'justify-content: center'"
                    class="mt"
                >
                    <template v-if="!$scope.IS_REGULAR_CUSTOMER">
                        <ProductWholesalerSizesSummary
                            v-if="wholesalerSizesQuantitiesToBuy"
                            :variations="product.variations"
                            :wholesaler-sizes-quantities-to-buy="wholesalerSizesQuantitiesToBuy"
                            class="mr-auto"
                        />
                        <span class="hidden-xs-up mt-2" style="width: 100%" />
                    </template>

                    <p
                        v-if="$scope.IS_REGULAR_CUSTOMER && visitAlertMessage"
                        class="text-info-4 mt-3 mb-3 text-left"
                        :style="{ width: '100%' }"
                    >
                        {{ visitAlertMessage }}
                    </p>

                    <!-- Preço na página quando o botão fixo sumir -->
                    <div v-if="isMobile" class="mb-3 mt-2">
                        <div
                            v-if="priceBadge"
                            class="badge-danger-1 flex-child-fixed text-regular text-uppercase product-price-promo mobile-badge-price"
                            style="padding: 5px 7px"
                        >
                            <strong>Promo!</strong> {{ priceBadge }}
                            <div
                                v-if="
                                    !priceGuarantee &&
                                    $scope.regulamento &&
                                    $scope.PROMO.id_tipo_oferta !== 3
                                "
                                class="open-rules-mobile"
                                @click="openRegulamento"
                            >
                                <i class="icon-cr icon-info cursor-pointer"></i>
                            </div>
                        </div>
                        <div class="d-flex flex-justify-between flex-align-center mb-2 w-full">
                            <div class="d-flex flex-justify-between flex-align-center">
                                <!-- Preço antigo -->
                                <p
                                    v-if="
                                        product.price_min > 0 &&
                                        product.price_min < product.price_max
                                    "
                                    class="text-secondary mr-2"
                                    :class="{
                                        h6: (this.product.type || {}).id !== 6,
                                    }"
                                >
                                    {{ $t('_shop.priceFrom') }}
                                </p>
                                <p
                                    v-if="
                                        $scope.IS_REGULAR_CUSTOMER &&
                                        product.price_old &&
                                        product.price < product.price_old
                                    "
                                    class="text-secondary flex-child-fixed hidden-md-up price-old-mobile mr-3"
                                    :class="{
                                        'price-old': product.price < product.price_old,
                                        'mt-2': (this.product.type || {}).id !== 6,
                                        'mt-0': (this.product.type || {}).id == 6,
                                        'ml-2': priceBadge,
                                    }"
                                >
                                    De:
                                    <span class="line-through">{{
                                        `${$f.formatMoney(product.price_old)} `
                                    }}</span>
                                </p>
                                <p
                                    class="d-flex flex-justify-between flex-align-center"
                                    :class="[
                                        priceBadge &&
                                        !(
                                            product.price_min > 0 &&
                                            product.price_min < product.price_max
                                        )
                                            ? 'ml-1'
                                            : '',
                                    ]"
                                >
                                    <span
                                        class="mr-1"
                                        v-if="
                                            !(
                                                product.price_min > 0 &&
                                                product.price_min < product.price_max
                                            )
                                        "
                                        >Por:</span
                                    >
                                    <span
                                        :class="[
                                            (this.product.type || {}).id == 6 ? 'my-0' : '',
                                            $scope.IS_REGULAR_CUSTOMER &&
                                            product.price_old &&
                                            product.price < product.price_old
                                                ? 'text-danger-1 flex-child-fixed'
                                                : 'text-info-4 ml-0',
                                        ]"
                                        class="text-bold"
                                    >
                                        {{
                                            $f.formatMoney(
                                                product.price_min > 0
                                                    ? product.price_min
                                                    : product.price
                                            )
                                        }}
                                    </span>
                                    <span
                                        class="ml-3"
                                        v-if="
                                            !(
                                                product.price_old &&
                                                product.price < product.price_old
                                            )
                                        "
                                        >ATÉ 4X</span
                                    >
                                </p>
                            </div>
                            <div
                                v-if="product.cashback_percentage && $scope.IS_REGULAR_CUSTOMER"
                                class="hidden-xs-up"
                                style="min-width: 136px"
                            >
                                <div class="text-uppercase cashback-text d-flex flex-align-center">
                                    <i class="icon-cr icon-cashback mr-2"></i>
                                    {{ product.cashback_percentage }}% de cashback
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Botão de Comprar -->
                    <ProductBuyButton
                        ref="buyButton"
                        :is-size-unavailable="showReprintMessage"
                        :product="product"
                        :size-to-buy="sizeToBuy"
                        :text="$t('_shop.toBuy')"
                        :wholesaler-sizes-quantities-to-buy="wholesalerSizesQuantitiesToBuy"
                        @alert="setAlert"
                        @done="reset"
                    />

                    <!-- Ícone de adicionar wishlist -->
                    <a
                        v-if="hasVariations && $scope.IS_REGULAR_CUSTOMER"
                        v-tooltip="$t('_shop._wishlist.add')"
                        class="hidden-md-down mt mb"
                        style="text-decoration: none"
                        @click="showWishlist = true"
                    >
                        <button
                            class="font-roboto-c d-flex flex-align-center flex-justify-center btn btn-xl btn-light"
                            style="gap: 0.4rem; width: 100%"
                        >
                            Adicionar à lista de desejos
                            <i class="icon-cr icon-wishlist accent"></i>
                        </button>
                    </a>

                    <div v-if="$scope.IS_REGULAR_CUSTOMER" id="product-alerts">
                        <ProductAdvantages class="text-yanone" />
                        <hr class="hidden-sm-up" />
                        <ProductLabels class="text-yanone hidden-sm-up" />
                    </div>
                </div>
            </div>
            <button v-else class="mt-3 btn btn-wide" @click="showReprint = true">
                Receber aviso quando voltar ao estoque
            </button>
        </template>
        <NewsletterSubscriber v-else v-slot="subscriber">
            <form :class="{ busy: subscriber.busy }" class="frame text-left">
                <p>Este produto não se encontra disponível em nosso catálogo.</p>
                <p>
                    Mas você pode informar seu email logo abaixo para se cadastrar na nossa
                    newsletter e receber novidades, lançamentos e promoções:
                </p>
                <TextField
                    v-model="subscriber.fields.name"
                    autocomplete="name"
                    label="Nome:"
                    type="text"
                />
                <TextField
                    v-model="subscriber.fields.email"
                    autocomplete="email"
                    label="Email:"
                    placeholder="nome@email.com"
                    type="email"
                />
                <LoadingButton
                    :loading="subscriber.busy"
                    class="btn btn-wide mt"
                    @click="subscriber.subscribe"
                >
                    Assinar
                </LoadingButton>
            </form>
        </NewsletterSubscriber>

        <ProductShipping
            v-if="!showReprintMessage && $scope.SHOW_CALCULATE_SHIPPING"
            :product="product"
            :variation="variationId"
        />
    </div>
</template>

<script>
    import SelectField from '../../../../../submodules/src/components/input/select-field/SelectField.vue';
    import TextField from '../../../../../submodules/src/components/input/text-field/TextField.vue';
    import throttle from 'lodash/throttle';
    import { EventBus, readCookie, resizeOrientationAware } from '../../../common/common';
    import { cartStore } from '../../common/store/cart-store';
    import { productStore } from '../../common/store/product-store';
    import Alert from '../common/Alert';
    import Countdown from '../common/Countdown';
    import LoadingButton from '../common/LoadingButton';
    import NewsletterSubscriber from '../newsletter/NewsletterSubscriber';
    import ProductAdvantages from './ProductAdvantages';
    import ProductLabels from './ProductLabels';
    import ProductBuyButton from './ProductBuyButton';
    import ProductPageComboPriceSmall from './ProductPageComboPriceSmall';
    import ProductPageRegularPrice from './ProductPageRegularPrice';
    import ProductReprint from './ProductReprint';
    import ProductShipping from './ProductShipping';
    import ProductWholesalerMultipleSizes from './ProductWholesalerMultipleSizes';
    import ProductWholesalerSingleSize from './ProductWholesalerSingleSize';
    import ProductWholesalerSizesSummary from './ProductWholesalerSizesSummary';
    import ProductWishlist from './ProductWishlist';
    import ProductVariations from './ProductVariations.vue';

    export default {
        name: 'ProductBuy',

        components: {
            ProductPageComboPriceSmall,
            ProductWholesalerSizesSummary,
            LoadingButton,
            TextField,
            NewsletterSubscriber,
            ProductWholesalerSingleSize,
            ProductWholesalerMultipleSizes,
            ProductBuyButton,
            ProductPageRegularPrice,
            Countdown,
            Alert,
            ProductReprint,
            ProductWishlist,
            ProductAdvantages,
            ProductLabels,
            SelectField,
            ProductShipping,
            ProductVariations,
        },

        props: {
            canBeBought: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                productStoreState: productStore.state,

                showFixedFrame: false,
                showFixedButton: true,
                alert: null,
                cartStoreState: cartStore.state,
                variationId: null,

                isMobile: false,
            };
        },

        watch: {
            'productStoreState.sizeToBuy.variation_id'() {
                this.variationId = (this.productStoreState || {}).sizeToBuy
                    ? this.productStoreState.sizeToBuy.variation_id
                    : null;
            },
        },

        computed: {
            product() {
                return this.productStoreState.product;
            },

            sizeToBuy: {
                get() {
                    return this.productStoreState.sizeToBuy;
                },

                set(val) {
                    this.productStoreState.sizeToBuy = val;
                },
            },

            sizeToReprint: {
                get() {
                    return this.productStoreState.sizeToReprint;
                },

                set(val) {
                    this.productStoreState.sizeToReprint = val;
                },
            },

            showReprint: {
                get() {
                    return this.productStoreState.showReprint;
                },

                set(val) {
                    this.productStoreState.showReprint = val;
                },
            },

            priceBadge() {
                if (this.product.promo_combo > 0 && this.displayPromoBadge) {
                    return this.product.promo_name;
                } else if (this.product.discount && this.$scope.IS_REGULAR_CUSTOMER) {
                    return this.product.discount + '% OFF';
                } else {
                    return null;
                }
            },

            displayPromoBadge() {
                return this.$scope.IS_REGULAR_CUSTOMER && !this.product.promo_group;
            },

            hasVariations() {
                return this.product.variations.length > 0;
            },

            priceGuarantee() {
                return (
                    this.product.discount &&
                    this.$scope.IS_REGULAR_CUSTOMER &&
                    !this.product.promo_combo &&
                    this.$scope.promo_guaranteed
                );
            },

            installmentPrice() {
                return this.$f.formatMoney(Math.floor((this.product.price / 4) * 100) / 100);
            },

            showWishlist: {
                get() {
                    return this.productStoreState.showWishlist;
                },

                set(val) {
                    this.productStoreState.showWishlist = val;
                },
            },

            wholesalerSizesQuantitiesToBuy: {
                get() {
                    return this.productStoreState.wholesalerSizesQuantitiesToBuy;
                },

                set(val) {
                    this.productStoreState.wholesalerSizesQuantitiesToBuy = val;
                },
            },

            showReprintMessage() {
                return (
                    this.$scope.IS_REGULAR_CUSTOMER &&
                    this.sizeToBuy &&
                    (!this.product.in_stock ||
                        (!this.sizeToBuy.virtual_stock && this.sizeToBuy.quantity === 0))
                );
            },

            cartIsBusy() {
                return this.cartStoreState.updatingCart;
            },

            stockSum() {
                return !this.product.in_stock
                    ? 0
                    : this.product.variations.reduce(
                          (previous, current) => previous + current.quantity,
                          0
                      );
            },

            hasStockAvailable() {
                return this.product.in_stock && (this.product.virtual_stock || this.stockSum > 0);
            },

            visitAlertMessage() {
                if (!this.product.product_views) return null;

                return this.product.product_views + ' pessoas acessaram esse produto recentemente';
            },

            isCombo() {
                return (
                    this.product.promo_instruction &&
                    this.product.promo_combo_unit_price &&
                    [25, 28].includes(this.product.promo_combo)
                );
            },
        },

        created() {
            this.sendPageView();
        },

        mounted() {
            this.resizeAwareRemoveCb = resizeOrientationAware(() => this.onResize(), true);
            this.updateFixedFrameVisibility();
            window.addEventListener(
                'scroll',
                throttle(() => this.updateFixedFrameVisibility(), 500)
            );
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        methods: {
            onResize() {
                this.isMobile = window.innerWidth < 768;
            },

            updateFixedFrameVisibility() {
                if (!this.$refs.buyButton || !this.$refs.buyButton.$el) {
                    this.showFixedFrame = false;

                    return;
                }

                const finishCartDiv = this.$refs.buyButton.$el.getBoundingClientRect().top;

                if (finishCartDiv < 0) {
                    this.showFixedButton = true; // Mostrar o botão fixo
                } else if (finishCartDiv <= window.innerHeight) {
                    this.showFixedButton = false; // Mostrar o botão posicionado
                } else if (finishCartDiv > window.innerHeight) {
                    this.showFixedButton = true; // Mostrar o botão fixo
                }

                if (this.canBeBought && this.hasStockAvailable && this.$refs.buyButton) {
                    this.showFixedFrame = this.$refs.buyButton.$el.getBoundingClientRect().top < 0;
                } else {
                    this.showFixedFrame = false;
                }
            },

            sendPageView() {
                if (!readCookie('_ga') && !this.$scope.id) return;
                this.axios.api.post('/hooks/customers/products/view', {
                    product_id: this.product.id,
                });
            },

            scrollToBuyTeste() {
                cr$.byId(this.$refs.productBuyAnchor).scrollIntoView();
            },

            reset() {
                for (const prop in this.wholesalerSizesQuantitiesToBuy) {
                    if (this.wholesalerSizesQuantitiesToBuy.hasOwnProperty(prop)) {
                        this.wholesalerSizesQuantitiesToBuy[prop] = 0;
                    }
                }
            },

            setAlert(alert) {
                this.alert = alert;
            },

            showReprintModalForSelectedSize(size) {
                this.sizeToReprint = size;
                this.showReprint = true;
            },
            openRegulamento() {
                EventBus.$emit('open-regulamento');
            },
        },
    };
</script>
