<template>
    <div>
        <div class="d-flex flex-align-center">
            <input
                type="text"
                :placeholder="PlaceHolderDiscount"
                class="text-uppercase input-cart input-btn-internal placeholder-AB"
                v-model="typedDiscount"
                id="discount"
                ref="discountInput"
                @blur="
                    $emit('blur');
                    PlaceHolderDiscount = 'Adicionar Cupom / Vale - Presente';
                "
                @focus="
                    $emit('focus');
                    PlaceHolderDiscount = 'Digite seu código';
                "
                :disabled="loadingDiscount"
                @keyup.enter="addDiscount()"
            />

            <button
                @click="addDiscount()"
                class="btn-internal-input-ab"
                :style="[!isMobile ? { height: '40px' } : {}]"
                :disabled="loadingDiscount"
            >
                <span>Aplicar</span>
            </button>
        </div>

        <div v-if="loadingDiscount">
            <Spinner small />
        </div>

        <Alert :alert="discountAlert" class="mt-2" />

        <template v-if="$scope.wholesalerDiscounts && $scope.wholesalerDiscounts.length > 0">
            <div
                v-for="wholesalerDiscount in $scope.wholesalerDiscounts"
                v-if="
                    !cart.discounts ||
                    !cart.discounts.data ||
                    cart.discounts.data.filter((d) => d.name === wholesalerDiscount.name).length ===
                        0
                "
                class="alert-success my cursor-pointer"
                @click="addWholesalerDiscount(wholesalerDiscount)"
            >
                <p style="margin: 0.1em 0">
                    Clique aqui&nbsp;para aproveitar seu cupom de
                    {{ $f.formatMoney(wholesalerDiscount.value) }}
                    {{ wholesalerDiscount.id_discount_type === 2 ? '!' : '%!' }}
                </p>
                <p style="margin: 0.1em 0">
                    Cupom: {{ wholesalerDiscount.name }}
                    <span v-if="wholesalerDiscount.troca_id && wholesalerDiscount.troca_id > 0">
                        (Troca {{ wholesalerDiscount.troca_id }})
                    </span>
                </p>
            </div>
        </template>

        <div
            v-if="cart.discounts && cart.discounts.data != null && cart.discounts.data.length > 0"
            class="mt-2"
        >
            <div class="d-flex flex-align-center">
                <p class="ml-0 my-0 mr-auto" style="font-size: 15px">CUPONS</p>
                <div class="text-right">
                    <a
                        class="text-right mx-0 my-0"
                        style="font-size: 15px"
                        @click.prevent.stop="showRules"
                    >
                        {{ isShowingRules ? 'Esconder Regras' : 'Ver Regras' }}
                    </a>
                </div>
            </div>
            <div v-for="discount in cart.discounts.data" v-show="isShowingRules" class="mb-3">
                <p class="text-yanone">
                    Regras do cupom: <strong>{{ discount.rules.name }}</strong>
                </p>
                <div>
                    <div v-if="discount.promo && discount.promo.regulamento">
                        <ul>
                            <li>{{ discount.promo.regulamento }}</li>
                        </ul>
                    </div>
                    <div v-else>
                        <ul>
                            <li v-for="rule in discount.rules.rule">{{ rule }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ul class="d-flex flex-spaced-fixed flex-wrap mb" style="font-size: 13px">
                <li
                    v-for="discount in cart.discounts.data"
                    v-if="!loadingDiscount"
                    class="flex-child-fixed"
                >
                    <a @click="removeDiscount(discount)">
                        <div class="chip chip-close">
                            {{ discount.name }}
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import Spinner from '../../../../../submodules/src/components/feedback/spinner/Spinner.vue';
    import { getScope, readCookie, setCookie, EventBus } from '../../../common/common';
    import Alert from '../common/Alert';
    import { cartService } from '../../../common/service/resource';

    export default {
        name: 'CartDiscount',

        components: { Alert, Spinner },

        props: ['loadingCart', 'cart', 'isLateralMenu', 'isMobile'],

        data() {
            return {
                PlaceHolderDiscount: 'Adicionar Cupom / Vale - Presente',
                typedDiscount: null,
                loadingDiscount: false,
                discountAlert: null,
                isShowingRules: false,
            };
        },

        watch: {
            cart: {
                immediate: true,
                handler(newCart, oldCart) {
                    if (newCart && this.$scope.IS_REGULAR_CUSTOMER) {
                        // verifica o voucher
                        const voucherToAdd = readCookie('voucher');

                        if (
                            voucherToAdd &&
                            (!this.cart.discounts ||
                                this.cart.discounts.data.filter(
                                    (d) => d.token === decodeURIComponent(voucherToAdd)
                                ).length === 0)
                        ) {
                            // voucher ainda não presente no carrinho
                            this.addDiscount(decodeURIComponent(voucherToAdd));
                            setCookie('voucher', '', -1);
                        }
                    }

                    this.isPrime = newCart.has_prime;
                },
            },
        },

        methods: {
            showRules() {
                this.isShowingRules = !this.isShowingRules;
            },

            addDiscount: function (discountToken) {
                this.loadingDiscount = true;
                this.$emit('loading-cart');
                const cartId = getScope('cart_id');

                const bodyFormData = new FormData();
                if (discountToken) {
                    bodyFormData.append('token', discountToken);
                } else {
                    bodyFormData.append('discountName', this.typedDiscount);
                }

                cartService
                    .addDiscount(cartId, bodyFormData)
                    .then(() => {
                        this.typedDiscount = '';
                        this.$emit('update-cart');
                        this.loadingDiscount = false;
                    })
                    .catch((response) => {
                        this.$emit('update-cart');
                        this.handleDiscountApiError(
                            response,
                            'Não foi possível adicionar o cupom.'
                        );
                    });
            },

            addWholesalerDiscount: function (wholeasalerDiscount) {
                this.typedDiscount = wholeasalerDiscount.name;
                this.addDiscount();
            },

            removeDiscount: function (discount) {
                const cartId = getScope('cart_id');
                this.$emit('loading-cart');
                cartService
                    .removeDiscount(cartId, discount.id)
                    .then(() => this.$emit('update-cart'))
                    .catch((response) =>
                        this.handleDiscountApiError(response, 'Não foi possível remover o cupom.')
                    );
            },

            handleDiscountApiError: function (response, defaultMessage) {
                try {
                    this.discountAlert = {
                        message: response.response.data.data.message,
                        isError: true,
                        timeout: 15000,
                    };
                    this.loadingDiscount = false;
                } catch (error) {
                    this.discountAlert = {
                        message: defaultMessage,
                        isError: true,
                        timeout: 15000,
                    };
                    this.loadingDiscount = false;
                }
            },
        },
    };
</script>
