<template>
    <div
        class="pos-relative nav-pill rounded-full px py-1 cursor-pointer text-nowrap"
        :class="classes"
        @click="onClick"
        @mouseenter="hovered = true"
        @mouseleave="hovered = false"
    >
        {{ title }}
    </div>
</template>

<script>
export default {
    name: 'NavbarPill',

    props: {
        value: [Number, String, Object],
        title: {
            type: String,
            required: true,
        },
    },

    inject: {
        pills: {
            default() {
                return {}
            },
        },
    },

    data() {
        return { hovered: false }
    },

    computed: {
        selected() {
            return this.pills.activePill() === this
        },

        classes() {
            const classes = []

            if (this.pills.orientation() === 'horizontal') classes.push('mx-2')
            if (this.pills.orientation() === 'vertical') classes.push('my-1')

            if (this.selected) {
                classes.push('selected')
                classes.push(this.pills.selectedClass() || '')
            } else {
                if (this.hovered) {
                    classes.push(this.pills.hoverClass() || '')
                } else {
                    classes.push(this.pills.idleClass() || '')
                }
            }

            return classes.join(' ')
        },
    },

    created() {
        this.pills.registerPill(this)
    },

    methods: {
        onClick() {
            this.pills.setActivePill(this)
        },
    },
}
</script>
