<template>
    <NewsletterSubscriber v-slot="subscriber" :fetch-customer="true" class="cr-form-group">
        <form :class="{ busy: subscriber.busy }">
            <TextField v-model="subscriber.fields.name" :disabled="!!$scope.id" label="Nome" />
            <TextField v-model="subscriber.fields.email" :disabled="!!$scope.id" label="Email" />

            <div class="form-inline mt">
                <Checkbox id="news-promo" v-model="subscriber.fields.promo" label="Promoções" />
                <Checkbox
                    id="news-releases"
                    v-model="subscriber.fields.releases"
                    label="Lançamentos"
                />
                <Checkbox id="news-content" v-model="subscriber.fields.content" label="Conteúdo" />
            </div>

            <LoadingButton
                :loading="subscriber.busy"
                class="btn btn-lg mt text-right"
                @click="subscriber.subscribe"
            >
                Atualizar preferências
            </LoadingButton>
        </form>
    </NewsletterSubscriber>
</template>

<script>
    import LoadingButton from '../common/LoadingButton';
    import NewsletterSubscriber from './NewsletterSubscriber';
    import Checkbox from '../../../../../submodules/src/components/input/checkbox/Checkbox.vue';
    import TextField from '../../../../../submodules/src/components/input/text-field/TextField.vue';

    export default {
        el: '#newsletter-form-vue',
        name: 'NewsletterPage',
        components: {
            NewsletterSubscriber,
            Checkbox,
            LoadingButton,
            TextField,
        },
    };
</script>
