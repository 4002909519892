var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-list-item mb",class:[!_vm.isLateralMenu ? 'product-list-item-top-border' : '']},[_c('li',[_c('div',{staticClass:"cart-product-info"},[(!_vm.isLateralMenu)?_c('div',{staticClass:"pos-relative",on:{"click":function($event){return _vm.$emit('zoom')}}},[(_vm.image)?_c('a',{staticClass:"flex-child-fixed border-radius overflow-hidden",attrs:{"href":_vm.link}},[_c('img',{staticClass:"lazy lazy-fade hidden-xs-down",attrs:{"alt":_vm.cartProduct.product.name,"data-src":`${_vm.image}&w=156&h=233&fit=crop`,"height":"233","width":"156"}}),_vm._v(" "),_c('img',{staticClass:"lazy lazy-fade hidden-xs-up",attrs:{"alt":_vm.cartProduct.product.name,"data-src":`${_vm.image}&w=104&h=156&fit=crop`,"height":"157","width":"104"}})]):_vm._e()]):_c('div',{on:{"click":function($event){return _vm.$emit('zoom')}}},[(_vm.image)?_c('a',{staticClass:"flex-child-fixed border-radius overflow-hidden",attrs:{"href":_vm.link}},[_c('img',{staticClass:"lazy lazy-fade",attrs:{"alt":_vm.cartProduct.product.name,"data-src":`${_vm.image}&w=104&h=156&fit=crop`,"height":"157","width":"104"}})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column pl-4"},[_c('div',{staticClass:"d-flex flex-justify-between flex-align-center mr-2",class:[!_vm.isLateralMenu ? 'mt-1' : '']},[_c('div',[(_vm.$scope.REQUEST_URI === '/sacola')?_c('a',{staticClass:"product-list-item-title",attrs:{"href":_vm.link}},[_c('strong',[_vm._v(_vm._s(_vm.cartProduct.product.name))])]):_c('a',{staticClass:"product-list-item-title",attrs:{"href":_vm.link}},[_vm._v("\n                            "+_vm._s(_vm.cartProduct.product.name)+"\n                        ")])])]),_vm._v(" "),_c('div',[_c('p',{staticClass:"product-list-item-subtitle"},[(
                            _vm.cartProduct.size !== 'Padrão' &&
                            (_vm.cartProduct.product.type || {}).url !==
                                'vale-presente'
                        )?[_c('span',[_vm._v("\n                            "+_vm._s((_vm.cartProduct.product.type || {}).url ===
                                'assinatura'
                                    ? _vm.$t('period')
                                    : '')+"\n                        ")])]:_vm._e()],2),_c('div',{staticClass:"hidden-xs-up product-list-item-subtitle"},[(_vm.cartProduct.product_model_name)?[_c('a',{staticClass:"ellipsis-text"},[_vm._v(" "+_vm._s(_vm.cartProduct.product_model_name)+" ")])]:_vm._e(),_vm._v(" "),(_vm.cartProduct.size !== 'Padrão')?[_c('a',[_vm._v(_vm._s(_vm.cartProduct.size))])]:_vm._e(),_vm._v(" "),(
                                        (_vm.cartProduct.product.type || {}).url ===
                                        'assinatura'
                                    )?[_c('br'),_vm._v(" "),_c('a',{staticClass:"text-accent",attrs:{"href":"central-de-ajuda/#reamaze#0#/kb/prime"}},[_vm._v("\n                                    "+_vm._s(_vm.$t('checkTerms'))+"\n                                ")])]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"hidden-xs-down product-list-item-subtitle"},[(_vm.cartProduct.product_model_name)?[_vm._v("\n                                "+_vm._s(_vm.cartProduct.product_model_name)+" \n                            ")]:_vm._e(),_vm._v(" "),(_vm.cartProduct.size !== 'Padrão')?[_c('span',[_vm._v(_vm._s(_vm.cartProduct.size))])]:_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.cartProduct.product_color_name && !_vm.isLateralMenu)?[_c('div',{staticClass:"d-flex flex-align-center mb-3 mt-3"},[_c('div',{staticClass:"badge-product-color mr-2 no-border",style:({'background-color': _vm.cartProduct.product_color_hexa})}),_vm._v(" "),_c('span',{staticClass:"color-name"},[_vm._v(_vm._s(_vm.cartProduct.product_color_name))])])]:_vm._e(),_vm._v(" "),((_vm.cartProduct.product.type || {}).url ==='assinatura')?[_c('br'),_vm._v(" "),_c('a',{staticClass:"text-accent",attrs:{"href":"central-de-ajuda/#reamaze#0#/kb/prime"}},[_vm._v("\n                                    "+_vm._s(_vm.$t('checkTerms'))+"\n                                ")])]:_vm._e()],2),_vm._v(" "),_c('p')]),_vm._v(" "),_c('div',{class:[!_vm.isLateralMenu ? 'mobile-cart-deadline' : '']},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.cartProduct.product_color_name))])]),_vm._v(" "),_c('div',{class:[!_vm.isLateralMenu ? 'mobile-cart-deadline' : '']},[(!_vm.cartProduct.can_be_bought)?_c('p',{staticClass:"badge-danger hidden-mobile"},[_vm._v("\n                        "+_vm._s(_vm.$t('unavailable'))+"\n                    ")]):((_vm.cartProduct.product.type || {}).url !==
                                'vale-presente' &&
                            (_vm.cartProduct.product.type || {}).url !==
                                'assinatura')?_c('div',{staticClass:"product-list-item-info mt-2"},[(!(_vm.cartProduct.quantity <= _vm.cartProduct.in_stock) && ((!_vm.cartProduct.virtual_stock && _vm.cartProduct.total_stock > 1) || _vm.cartProduct.virtual_stock) &&  _vm.cartProduct.has_quantity)?_c('p',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.productionTootlip),expression:"productionTootlip"}],staticClass:"badge-default"},[_vm._v("\n                            "+_vm._s(_vm.$t('toOrder'))+" "),_c('i',{staticClass:"icon-cr icon-info ml-1"})])]):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"font-size":"12px"}},[(
                            (_vm.cartProduct.product.type || {}).url !==
                                'vale-presente' &&
                            (_vm.cartProduct.product.type || {}).url !==
                                'assinatura' &&
                            _vm.cartProduct.product.virtual_stock &&
                            _vm.cartProduct.quantity > _vm.cartProduct.in_stock
                            && _vm.cartProduct.has_quantity)?[(_vm.showProductionDate && _vm.fastDelivery)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                    Prazo posterior a 23/12\n                                ")]):_vm._e()]:(_vm.cartProduct.quantity <= _vm.cartProduct.in_stock)?_c('p',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.inStockTooltip),expression:"inStockTooltip"}],staticClass:"badge-primary"},[_vm._v("\n                                    "+_vm._s(_vm.$t('_shop._stock.inStock'))+" "),_c('i',{staticClass:"icon-cr icon-info ml-1"})])]):_vm._e(),_vm._v(" "),(
                            _vm.cartProduct.product.is_active &&
                            _vm.cartProduct.total_stock >= 1 &&
                            _vm.cartProduct.total_stock <= 2 &&
                            _vm.cartProduct.product.in_stock
                        )?[(_vm.cartProduct.total_stock === 1)?_c('p',{staticClass:"badge-info"},[_vm._v("\n                                "+_vm._s(_vm.$tc('_shop._stock.lastUnit', 1))+"\n                            ")]):_c('p',{staticClass:"badge-info"},[_vm._v("\n                                    "+_vm._s(_vm.$tc('_shop._stock.lastUnits', 2))+"\n                                ")])]:_vm._e(),_vm._v(" "),(
                            (_vm.cartProduct.product.is_active &&
                                !_vm.cartProduct.has_quantity) ||
                            !_vm.cartProduct.product.in_stock
                        )?_c('p',{staticClass:"badge-danger"},[_vm._v("\n                                "+_vm._s(_vm.$t('_shop._stock.qtyUnavailable'))+"\n                            ")]):(!_vm.cartProduct.product.is_active)?_c('p',{staticClass:"badge-danger"},[_vm._v("\n                                "+_vm._s(_vm.$t('unavailable'))+"\n                            ")]):_vm._e()],2),_vm._v(" "),(
                            _vm.cartProduct.produto_id === _vm.kitProductId &&
                            !_vm.hasMinToKit
                        )?_c('p',{staticClass:"badge-danger"},[_vm._v("\n                            "+_vm._s(_vm.$t('_cart.onlyOrdersAboveX', [_vm.$f.formatMoney(30)]))+"\n                        ")]):_vm._e(),_vm._v(" "),(
                            _vm.cartProduct.produto_id === _vm.specialMugProductId &&
                            !_vm.cart.gift_message
                        )?_c('p',{staticClass:"badge-danger"},[_vm._v("\n                            "+_vm._s(_vm.$t('_cart.enterCustomName'))+"\n                        ")]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex mt-2 flex-align-center select-and-prices",class:[_vm.isLateralMenu ? 'flex-justify-between' : '']},[(!_vm.$scope.IS_REGULAR_CUSTOMER)?[_c('i',{staticClass:"icon-cr icon-less icon-cr-hover hidden-md-down",attrs:{"title":_vm.$tc('_cart.toRemoveXItems', 1)},on:{"click":function($event){return _vm.$emit('remove-one')}}}),_vm._v(" "),_c('input',{staticClass:"my-0 hidden-md-down text-center",attrs:{"max":_vm.maximumQuantity,"type":"text"},domProps:{"value":_vm.cartProduct.quantity},on:{"change":function($event){return _vm.$emit('quantity-change', $event)}}}),_vm._v(" "),_c('i',{staticClass:"icon-cr icon-plus icon-cr-hover hidden-md-down",class:{
                                    busy:
                                        _vm.maximumQuantity &&
                                        _vm.cartProduct.quantity >= _vm.maximumQuantity,
                                },attrs:{"title":_vm.$tc('_cart.toAddXItems', 1)},on:{"click":function($event){return _vm.$emit('add-one')}}})]:(((!_vm.cartProduct.virtual_stock && _vm.cartProduct.total_stock > 0) || _vm.cartProduct.virtual_stock))?_c('div',{staticClass:"cr-select my-0"},[_c('select',{staticClass:"select-mobile mt-0",attrs:{"data-produto-id":_vm.cartProduct.product.id,"data-quantity":_vm.cartProduct.quantity,"data-variation-id":_vm.cartProduct.variation_id},on:{"change":function($event){return _vm.$emit('quantity-change', $event)}}},_vm._l((_vm.maximumQuantity),function(index){return _c('option',{domProps:{"selected":_vm.cartProduct.quantity === index,"value":index}},[_vm._v("\n                                "+_vm._s(index)+"\n                            ")])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex prices-wrap"},[(_vm.isLateralMenu && _vm.cartProduct.product.price_old > 0 &&
                        _vm.cartProduct.product.price_old > _vm.cartProduct.product.price &&
                        _vm.cartProduct.product.price_old > _vm.cartProduct.total_price
                        && _vm.cartProduct.product.is_active)?_c('p',{staticClass:"text-default-3 line-through ml-3 mr-2",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.cartProduct.product.price_old)))]):(_vm.isLateralMenu && _vm.cartProduct.product.is_gift && _vm.cartProduct.product.price <= 1)?_c('p',{staticClass:"text-default-3 line-through ml-3 mr-2",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.cartProduct.product.price)))]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"product-list-item-price",class:[!_vm.isLateralMenu ? 'hidden-md-up ml-2' : ''],staticStyle:{"font-size":"14px"}},[(_vm.cartProduct.product.is_gift && _vm.cartProduct.product.price <= 1)?_c('strong',[_vm._v("\n                                Grátis\n                            ")]):_c('strong',[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.cartProduct.total_price)))])])]),_vm._v(" "),(!_vm.isLateralMenu)?_c('div',{staticClass:"hidden-mobile"},[_c('div',{staticClass:"d-flex flex-align-center"},[_c('a',{staticClass:"cart-product-remove icon-cr icon-cr-hover icon-trash",class:{"ml-3": ((!_vm.cartProduct.virtual_stock && _vm.cartProduct.total_stock > 0) || _vm.cartProduct.virtual_stock)},attrs:{"title":_vm.$t('_cart.toRemoveItem')},on:{"click":function($event){return _vm.$emit('remove')}}})])]):_vm._e()],2),_vm._v(" "),(!_vm.cartProduct.can_be_bought)?_c('p',{staticClass:"badge-danger hidden-mobile",class:[_vm.isLateralMenu ? 'hidden-lateral-cart' : '']},[_vm._v("\n                    "+_vm._s(_vm.$t('unavailable'))+"\n                ")]):((_vm.cartProduct.product.type || {}).url !==
                                'vale-presente' &&
                            (_vm.cartProduct.product.type || {}).url !==
                                'assinatura')?_c('div',{staticClass:"product-list-item-info mt-2 hidden-mobile",class:[_vm.isLateralMenu ? 'hidden-lateral-cart' : '']},[(!(_vm.cartProduct.quantity <= _vm.cartProduct.in_stock) && ((!_vm.cartProduct.virtual_stock && _vm.cartProduct.total_stock > 1) || _vm.cartProduct.virtual_stock) &&  _vm.cartProduct.has_quantity)?_c('p',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.productionTootlip),expression:"productionTootlip"}],staticClass:"badge-default"},[_vm._v("\n                            "+_vm._s(_vm.$t('toOrder'))+" "),_c('i',{staticClass:"icon-cr icon-info ml-1"})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"hidden-xs-down"},[(
                            (_vm.cartProduct.product.type || {}).url !==
                                'vale-presente' &&
                            (_vm.cartProduct.product.type || {}).url !==
                                'assinatura' &&
                            _vm.cartProduct.product.virtual_stock &&
                            _vm.cartProduct.quantity > _vm.cartProduct.in_stock &&
                            _vm.cartProduct.has_quantity
                        )?[(_vm.showProductionDate && _vm.fastDelivery)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                Prazo posterior a 23/12\n                            ")]):_vm._e()]:(_vm.cartProduct.quantity <= _vm.cartProduct.in_stock)?_c('p',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.inStockTooltip),expression:"inStockTooltip"}],staticClass:"badge-primary"},[_vm._v("\n                              "+_vm._s(_vm.$t('_shop._stock.inStock'))+" "),_c('i',{staticClass:"icon-cr icon-info ml-1"})])]):_vm._e(),_vm._v(" "),(
                            _vm.cartProduct.product.is_active &&
                            _vm.cartProduct.total_stock >= 1 &&
                            _vm.cartProduct.total_stock <= 2 &&
                            _vm.cartProduct.product.in_stock
                        )?[(_vm.cartProduct.total_stock === 1)?_c('p',{staticClass:"badge-info"},[_vm._v("\n                            "+_vm._s(_vm.$tc('_shop._stock.lastUnit', 1))+"\n                        ")]):_c('p',{staticClass:"badge-info"},[_vm._v("\n                                "+_vm._s(_vm.$tc('_shop._stock.lastUnits', 2))+"\n                            ")])]:_vm._e(),_vm._v(" "),(
                            (_vm.cartProduct.product.is_active &&
                                !_vm.cartProduct.has_quantity) ||
                            !_vm.cartProduct.product.in_stock
                        )?_c('p',{staticClass:"badge-danger"},[_vm._v("\n                            "+_vm._s(_vm.$t('_shop._stock.qtyUnavailable'))+"\n                        ")]):(!_vm.cartProduct.product.is_active)?_c('p',{staticClass:"badge-danger"},[_vm._v("\n                            "+_vm._s(_vm.$t('unavailable'))+"\n                        ")]):_vm._e()],2),_vm._v(" "),(
                            _vm.cartProduct.produto_id === _vm.kitProductId &&
                            !_vm.hasMinToKit
                        )?_c('p',{staticClass:"badge-danger"},[_vm._v("\n                        "+_vm._s(_vm.$t('_cart.onlyOrdersAboveX', [_vm.$f.formatMoney(30)]))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            _vm.cartProduct.produto_id === _vm.specialMugProductId &&
                            !_vm.cart.gift_message
                        )?_c('p',{staticClass:"badge-danger"},[_vm._v("\n                        "+_vm._s(_vm.$t('_cart.enterCustomName'))+"\n                    ")]):_vm._e()]):_vm._e()])]),_vm._v(" "),_c('div',{class:[!_vm.isLateralMenu ? 'mobile-cart-deadline' : '']},[_c('div',{staticClass:"pos-absolute pos-right px-1 py-1 pos-top"},[_c('a',{staticClass:"cart-product-remove icon-cr icon-cr-hover icon-trash",attrs:{"title":_vm.$t('_cart.toRemoveItem')},on:{"click":function($event){return _vm.$emit('remove')}}})])]),_vm._v(" "),_c('div',{class:[_vm.isLateralMenu ? 'hidden-lateral-cart' : 'hidden-md-down']},[_c('div',{staticClass:"d-flex flex-align-center mt-2"},[(_vm.cartProduct.product.price_old > 0 && _vm.cartProduct.product.price_old > _vm.cartProduct.product.price &&
                _vm.cartProduct.product.price_old > _vm.cartProduct.total_price
                 && _vm.cartProduct.product.is_active)?_c('p',{staticClass:"text-default-3 line-through",class:[!_vm.isLateralMenu ? 'hidden-md-down' : ''],staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.cartProduct.product.price_old)))]):(_vm.cartProduct.product.is_gift && _vm.cartProduct.product.price <= 1)?_c('p',{staticClass:"text-default-3 line-through",class:[!_vm.isLateralMenu ? 'hidden-md-down' : ''],staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.cartProduct.product.price)))]):_vm._e(),_vm._v(" "),(_vm.cartProduct.product.is_gift && _vm.cartProduct.product.price <= 1 && _vm.cartProduct.product.is_active)?_c('strong',{staticClass:"ml-3",staticStyle:{"font-size":"14px"}},[_vm._v("\n                    Grátis ")]):(_vm.cartProduct.product.is_active)?_c('strong',{staticClass:"ml-3",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.cartProduct.total_price)))]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }