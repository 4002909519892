<template>
    <div v-if="sizesQuantities" class="text-yanone">
        <template v-if="colors && colors.length > 0">
            <div class="mb">
                <div>
                    <p class="h6">
                        {{ $t('color') }}:
                        <strong>{{ selectedColor.name }}</strong>
                    </p>
                </div>

                <nav class="cr-option-group product-variations-colors">
                    <div
                        v-for="color in colors"
                        :class="{
                            'cr-option-selected': selectedColor === color,
                        }"
                        class="cr-option cr-option-squared"
                        style="background-color: white; padding: 2px"
                        @click="setColor(color)"
                    >
                        <div
                            :style="{ 'background-color': color.code }"
                            class="product-color-option"
                        ></div>
                    </div>
                </nav>
            </div>
        </template>

        <div v-if="modelings && modelings.length > 0" id="product-wholesaler-modelings">
            <div class="text-center hidden-md-up mb-4 mt">
                <hr />
                <span class="h3 text-bold"> {{ $t('_product.models') }} </span>
                <span
                    class="text-accent cursor-pointer text-underline"
                    @click="$emit('show-sizes-table')"
                >
                    {{ $t('_product.toSeeSizesGuide') }}
                </span>
            </div>

            <div class="hidden-md-down">
                <div class="d-flex flex-align-center product-wholesaler-modeling">
                    <div class="product-wholesaler-modeling-title">
                        <span class="h5 my-0 text-bold">
                            {{ $t('_product.models') }}
                        </span>
                        <small
                            class="text-accent cursor-pointer text-underline"
                            @click="$emit('show-sizes-table')"
                        >
                            {{ $t('_product.toSeeSizesGuide') }}
                        </small>
                    </div>
                    <div class="d-flex flex-item-grow">
                        <div
                            v-for="size in sizeNames"
                            :style="`flex: 0 0 ${100 / sizeNames.length}%`"
                            class="px text-bold text-center"
                        >
                            <p>{{ size }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-for="modeling in modelings" class="d-flex product-wholesaler-modeling mb">
                <div class="product-wholesaler-modeling-title h5 mt-2">
                    {{ modeling.name }}
                </div>

                <div id="product-wholesaler-sizes" class="flex-item-grow">
                    <div
                        v-for="size in sizes"
                        v-if="modeling.id === size.product_model_id"
                        class="px product-wholesaler-size"
                        :style="`flex: 0 0 ${100 / sizeNames.length}%`"
                    >
                        <div class="hidden-md-up">
                            <label class="d-block text-center text-bold mb-2 mt">
                                {{ size.size_name }}
                            </label>
                        </div>
                        <ProductWholesalerSizeField
                            :key="size.variation_id"
                            v-model="sizesQuantities[size.variation_id]"
                            :product="product"
                            :size="size"
                            @open-reprint="$emit('open-reprint', $event)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="d-flex flex-spaced-fixed flex-wrap">
            <div
                v-for="size in sizes"
                class="flex-child-fixed"
                style="flex-shrink: 0; flex-basis: 125px"
            >
                <p class="h6 text-bold">
                    {{ size.size_name }}
                </p>
                <ProductWholesalerSizeField
                    :key="size.variation_id"
                    v-model="sizesQuantities[size.variation_id]"
                    :product="product"
                    :size="size"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import ProductWholesalerSizes from './ProductWholesalerSizes';
    import ProductWholesalerSizeField from './wholesaler/ProductWholesalerSizeField';
    import NumberField from '../../../../../submodules/src/components/input/number-field/NumberField.vue';

    export default {
        extends: ProductWholesalerSizes,

        name: 'ProductWholesalerMultipleSizes',

        components: { ProductWholesalerSizeField, NumberField },

        computed: {
            sizeNames() {
                return this.sizes
                    .map((s) => s.size_name)
                    .filter((s, index, sizes) => index === sizes.indexOf(s));
            },
        },
    };
</script>
